import ThemedText from "../../../components/ThemedText";
import { IonGrid, IonRow, IonCol } from "@ionic/react";
import { useState, useRef, useEffect } from "react";

// import { fadeInToLeft, fadeInToRight, fadeOutToLeft, fadeOutToRight } from "../../../utilities/animation";
import "./futureOutlook.css";

import futureOutlookImage from "../assets/FutureOutlook/6.png";
import handyMarker from "../assets/FutureOutlook/2.png";


export default function FutureOutlook() {
    const [isVisible, setIsVisible] = useState(false);
    const textRef = useRef(null);
    const pictureRef = useRef(null);

    const [phrases, setPhrases] = useState([]);
    const [currentPhraseIndex, setCurrentPhraseIndex] = useState(0);

    useEffect(() => {
        const text = "Eine App - Alle Hilfsmöglichkeiten auf einen Blick - Schnellstmöglich Hilfe erhalten  - Zusammen die Welt verbessern";
        const phrasesArray = text.split("-");
        setPhrases(phrasesArray);
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentPhraseIndex((prevIndex) => (prevIndex + 1) % phrases.length);
        }, 2000);

        return () => clearInterval(interval);
    }, [phrases]);




    
    useEffect(() => {

        const observer = new IntersectionObserver(
            ([textEntry, imgEntry]) => {
                if(textEntry){
                    if (textEntry.isIntersecting) {
                        setIsVisible(1);
                    }
                }
                if(imgEntry){
                    if (!imgEntry.isIntersecting) {
                        setIsVisible(0);
                    }
                }

            },
            
        );
        const tRef  = textRef.current;
        const pRef = pictureRef.current;
        if (tRef && pRef) {
            observer.observe(tRef);
            observer.observe(pRef);
        }
    
        return () => {
            if (tRef && pRef) {
                observer.unobserve(tRef);
                observer.unobserve(pRef);
            }
        };
    }, []);

    return (
        <IonGrid className="bg-futureOutlook">
            <IonGrid
                style={{
                    opacity: isVisible ? 1 : 0,
                    transition: 'opacity 1s ease-in-out',
                    padding: 0,
                    margin: 0,
                }}
            >
                <ThemedText variant="h1-bright">Unsere Vision</ThemedText>
            </IonGrid>
            <IonRow>
                <IonGrid
                    className="row-grid-vision"
                    style={{
                        opacity: isVisible ? 1 : 0,
                        transition: 'opacity 1s ease-in-out',
                        flexDirection:"column"
                    }}

                >
                    <IonRow>
                    <ThemedText variant="h2-bright">
                        Wir wollen die Welt zu einem besseren Ort machen, indem Menschen, die Hilfe brauchen, mit Menschen zusammengebracht werden, die helfen wollen.
                    </ThemedText>
                    <br/>
                    </IonRow>
                    <IonRow style={{ display: "flex", justifyContent: "center"}}>
                        <p className = "splitted-phrase">
                            {phrases.map((phrase, index) => (
                                <span className = "span-phrase" key={index} style={{ opacity: index === currentPhraseIndex ? 1 : 0, transition: "opacity 1s ease-in-out" }}>
                                    {phrase}
                                </span>
                            ))}
                        </p>
                    </IonRow>
                </IonGrid>

            </IonRow>
            <IonRow style={{ 
                opacity: isVisible ? 1 : 0,
                transition: 'opacity 1s ease-in-out',
                padding: "10px", paddingBottom: "0px", paddingTop:"0px", paddingRight: "0px" }}>
                <IonCol ref={textRef} sizeXl="6" sizeXs="12" style={{ display: "flex", flexDirection: "column", padding: "20px", textAlign: "justify", alignItems: "center", justifyContent: "center" }}>
                    <ThemedText variant="p-bright">
                    Als junges Startup ist unsere Zeit neben Beruf und Studium knapp, weshalb sich unsere App derzeit noch in der Entwicklungsphase befindet. Dennoch fügen wir kontinuierlich neue Funktionen hinzu, wie etwa eine interaktive GPS-Karte, die Gesuche in der Nähe anzeigt.
                    </ThemedText>
                    <br />
                    <ThemedText variant="p-bright">
                    Auch unsere Website entwickeln wir stetig weiter, um zusätzliche Funktionen zu integrieren. In Zukunft soll es möglich sein, Leistungen einfach und bequem zu buchen und zu bezahlen. Zudem planen wir die Einführung von Kundenkonten, damit Nutzer einen Überblick über ihre Aufträge und ihr verfügbares Budget oder Kontingent behalten.
                    </ThemedText>
                    <br/>
                    <ThemedText variant="p-bright">
                    Unser langfristiges Ziel ist es, eine Plattform zu schaffen, auf der sowohl Privatpersonen als auch Organisationen Inserate für Alltags- oder ehrenamtliche Helfer einstellen können. Helfer sollen diese Aufträge dann unkompliziert über die App oder Website annehmen können.                    </ThemedText>
                </IonCol>
                <IonCol sizeXl="6" sizeXs="12" style = {{padding: 0}}>
                    <IonGrid  ref={pictureRef} style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", padding: 0 }}>
                        <img className="visionPic2"  src={handyMarker} alt="Unsere Vision" /> 
                        <img className="visionPic"  src={futureOutlookImage} alt="Unsere Vision" /> 
                    </IonGrid>
                </IonCol>
            </IonRow>
        </IonGrid>
    )
}
//TODO: Darstelllungsfehler image 2
