import React from 'react';
import {Card, CardActionArea, CardMedia, CardContent } from '@mui/material';

import picDennis from '../../../SVG/Selbstvorstellungen/Pics_With_Bg/DennisWithBg1.jpg';
import picMario from '../../../SVG/Selbstvorstellungen/Pics_With_Bg/Mario.png';
import picKristin from '../../../SVG/Selbstvorstellungen/Pics_With_Bg/Kristin.png';

import { IonCol } from '@ionic/react';

import './teamSection.css';

const founders = {
    dennis: {
        firstname: "Dennis",
        surname: "Stanielewitz",
        shortBio: "",
        job: "Psychologie und Informatik",
        whatIWork: "B.Sc. in Psychologie und momentaner Masterstudent an der LMU für Informatik sowie Bachelorstudent der FH für regenerative Energien. Für Miete und Essen arbeite ich zudem als Hardwareentwickler/-designer.",
        whatIDo: "Ich helfe dabei, die Website zu entwickeln.",
        hobbies: "Ob von Fantasy über Thriller bis adlerianischer Psychologie wird alles gelesen! Doch mein Lieblingshobby ist und bleibt Gaming! Da geht nichts über ein Souls-Game und nebenbei Streams oder Anime! Obwohl ich schwer nein sage zu einem Abend mit Punk-Rock und Metal ;)",
        quote: "May your heart be your guiding key!",
        image: picDennis
    },
    kristin: {
        firstname: "Kristin",
        surname: "Schermer",
        shortBio: "",
        job: "Psychologie und Geschichte",
        whatIWork: "Ich studiere Psychologie im Master und gehe meiner Leidenschaft für Geschichte nebenher in einem Bachelor-Geschichtsstudium nach. Zusätzlich arbeite ich außerdem in einem Medienpädagogischen Unternehmen in der Forschung.",
        whatIDo: "Ich bin für die Erstellung von Umfragen und deren statistischer Auswertung, User Experience und Präsentationen zuständig.",        
        hobbies: "Ich mache gerne Yoga, backe, gehe in die Oper und ins Ballett und lese gerne – von True Crime bis Themen der Kunstgeschichte.",
        quote: "Fügen Sie hier ihr Zitat ein!",
        image: picKristin
    },
    mario: {
        firstname: "Mario",
        surname: "Faschinger",
        shortBio: "",
        job: "Sales Manager",
        whatIWork: "Meine Karriere begann mit der Absolvierung der Einzelhandelsausbildung. Mit etwa 3 Jahren Erfahrung als Filialleiter und weiteren 6 Jahren im Vertrieb als Senior Sales Manager verfüge ich über umfangreiche Kenntnisse im Bereich Sales B2B & B2C.",
        whatIDo: "Der Bereich Finance and Sales ist meine Spezialität, und ich freue mich darauf, ihn zu übernehmen.",
        hobbies: "Neben meinem beruflichen Engagement liebe ich es zu zeichnen und zu reisen.",
        quote: "Verweile nicht in der Vergangenheit, träume nicht von der Zukunft. Konzentriere dich auf den gegenwärtigen Moment.",
        image: picMario
    },
}


export function FounderCard ({ onClick, founder}) {

    const selectedFounder = founders[founder];

    return (
        <IonCol style = {{display: "flex", justifyContent: "center"}}>
            <Card 
            sx={{
                maxWidth: '280px',

                '& img': {
                    filter: 'brightness(110%)',
                },
                '&:hover': {
                    boxShadow: "0px 0px 40px rgba(38, 0, 255, 0.555)",
                    transform: 'scale(1.05)', 
                    transition: 'transform 0.5s',
                }
            }}
            onClick={() => onClick(1)}
            >
                <CardActionArea>
                    <CardMedia
                        className='founder-image'
                        component="img"
                        image={selectedFounder.image}
                        style={{ height: 'clamp(400px,10vw,600px)', objectFit: 'cover' }} //Höhe der Kartenbilder!
                        />
                    <CardContent style={{ minHeight: 'content-fit', background:"#E1EBEE"}}>
                        <p className = "p-founder-name">
                            {selectedFounder.firstname}<br/>
                            {selectedFounder.surname}
                        </p>
                    </CardContent>
                </CardActionArea>
            </Card>
        </IonCol>
    )
};

export function BioFounder({ founder }) {
    const selectedFounder = founders[founder];

    return (
        <div className="bio-container"> 
            <h3>{selectedFounder.name}</h3>
            <p><strong>Was ist dein Arbeitsumfeld?</strong> <br />{selectedFounder.whatIWork}</p>
            <p><strong>Was machst du hier?</strong>         <br />{selectedFounder.whatIDo}</p>
            <p><strong>Was sind deine Hobbys?</strong>      <br />{selectedFounder.hobbies}</p>
            <p><strong>Sonst noch was?</strong>             <br />{selectedFounder.quote}</p>
        </div>
    );
}
