import HeaderBar from "../components/header/HeaderBar";
import {  IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';



//TODO: App Rooting + Webapp Rooting

export default function RootLayout({ children }) {

    return (
        <IonReactRouter>
            <HeaderBar />
            <IonRouterOutlet>
                {children}
            </IonRouterOutlet>
        </IonReactRouter>
    );
}
