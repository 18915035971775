import { IonCol, IonGrid, IonLabel, IonRow } from '@ionic/react';
import React from 'react';
import { useEffect, useRef} from 'react';

import "./portfolio.css"
import { addingBlue, substractRed } from '../../utilities/animation';

export default function ShoppingCartRow({ cost, quantity }) {


    const costsRef = useRef(null);
    const quantityRef = useRef(null);

    const costRefAnimationBlue = addingBlue(costsRef);
    const quantityRefAnimationBlue = addingBlue(quantityRef);
    const costRefAnimationRed = substractRed(costsRef);
    const quantityRefAnimationRed = substractRed(quantityRef);

    const oldCostRef = useRef(cost);
    const oldQuantityRef = useRef(quantity);


    useEffect(() => {
        if (cost && oldCostRef.current !== cost) {
            if(oldCostRef.current > cost){
                costRefAnimationRed.play();
            }
            if(oldCostRef.current < cost){
                costRefAnimationBlue.play();
            }
        }
        if (quantity && oldQuantityRef.current !== quantity) {
            if(oldQuantityRef.current > quantity){
                quantityRefAnimationRed.play();
            }
            if(oldQuantityRef.current < quantity){
                quantityRefAnimationBlue.play();
            }
        }
        oldCostRef.current = cost;
        oldQuantityRef.current = quantity;
    }, [cost, quantity, costRefAnimationBlue, quantityRefAnimationBlue, costRefAnimationRed, quantityRefAnimationRed]);





    return (
        <IonGrid style={{ padding: 0, margin: 0 }}>
            <IonRow className="shoppingCartRow">
                <IonCol style={{ padding: "5px", display: "flex", flexDirection: "column" }}>
                    <IonLabel  className = "p-text" ref = {costsRef}><strong>Gesamtpreis: </strong>{((cost)*1.19).toFixed(2)} €</IonLabel>
                    {
                    quantity > 1 ? 
                        <IonLabel className = "p-text" ref = {quantityRef}><strong>Menge: </strong>{quantity} Hilfsleistungen</IonLabel>
                    : 
                        <IonLabel  className = "p-text" ref = {quantityRef} ><strong>Menge:</strong> {quantity} Hilfsleistung</IonLabel>
                    }
                    
                </IonCol>
            </IonRow>
        </IonGrid>
    );
};