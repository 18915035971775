import { createSlice } from "@reduxjs/toolkit"; //1. Import function

const logoSlice = createSlice({                 //2. create const, that creates a Slice
    name: "logo",                               //3. name of the slice
    initialState: {
        logoState: true                              //4. initial state
    },                        //4. initial state
    reducers: {                                 //5. reducers
        setLogo: (state, action) => {           //6. setLogo
            state.logoState = action.payload || {};  //7. set logo to action.payload
        }
    }
});
export const { setLogo } = logoSlice.actions;   //8. export setLogo
export default logoSlice.reducer;               //9. export default reducer

