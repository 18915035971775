import { createSlice } from "@reduxjs/toolkit";

const shoppingCartSlice = createSlice({
    name: "shoppingCart",
    initialState: {
        shoppingCartState: []  
    },
    reducers: {
        setShoppingCart: (state, action) => {
            state.shoppingCartState = action.payload || [];
        }
    }
});

export const { setShoppingCart } = shoppingCartSlice.actions;
export default shoppingCartSlice.reducer;
