import { IonContent, IonGrid, IonCol, IonRow, IonPage, IonButton, IonCheckbox, IonItemDivider} from '@ionic/react';
import React, { useEffect } from 'react';
import { useState, useRef} from 'react';
import { Link } from 'react-router-dom';
import SpaceForHeader from '../../components/header/SpaceForHeader';
import ThemedText from '../../components/ThemedText';

import './portfolio.css';

import beschaffung from "../homePage/assets/DeepInfosKrameda/begleitung.jpg";
import sozialeInteraktion from "../homePage/assets/DeepInfosKrameda/SI3.jpg";
import tierpflege from "../homePage/assets/DeepInfosKrameda/tierpflege1.jpg";
import begleitung from "../homePage/assets/DeepInfosKrameda/beschaffung1.jpg";
import haushalt from "../homePage/assets/DeepInfosKrameda/Garten2.jpg";
import technik from "../homePage/assets/DeepInfosKrameda/technik2.jpg";

import ShoppingCartRow from './/ShoppingCartRow';

// DATEPICKER
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import QuestionmarkModal from '../../components/QuestionmarkModal';

import { setShoppingCart } from '../../slices/shoppingCartSlice';
import { useDispatch } from 'react-redux';
import { Divider } from '@mui/material';
import dayjs from 'dayjs';

export default function Portfolio() {


    const [warenkorb, setWarenkorb] = useState([]);
    const [wishDay] = useState(null);
    const [beginTime] = useState(null);


    const shoppingCart = useRef(null);
    // const auftragModalTitle = "Stunden als Kontingent kaufen?";
    // const auftragModalText = "Sie können Stunden als Kontingent kaufen und diese dann für die Buchung von Aufträgen verwenden. Buchen Sie flexibel und sparen Sie Geld. Die Stunden können für jede Art von Dargestellten Aufträgen verwendet verwenden, zum Beispiel für Tierpflege oder BEgleitung!";
    const messageModalTitle = "Warum die Nachricht?";
    const messageModalText = 
    `Bitte geben Sie uns genauere Informationen zu Ihrem Auftrag, damit wir uns bestmöglich auf Ihre Bedürfnisse einstellen können.
    
    Beispiele:
    - Wann soll der Auftrag erledigt werden?
    - Was genau soll erledigt werden?
    - Wo brauchen sie die Hilfe?
    - Muss auf etwas besonders geachtet werden?
    - Benötigen wir Hilfsutensilien?
    - Sonstige Informationen`;
    const [formData, setFormData] = useState({
        firstname: '',
        surname: '',
        plz: '',
        street: '',
        contact: '',
        message: '',
        bday: '',
        from: 'riseknot@gmail.com', // Absender-E-Mail-Adresse
        to: 'riseknot@gmail.com', // Empfänger-E-Mail-Adresse
        subject: 'Neuer Auftrag!', // Betreff der E-Mail
        warenkorb: warenkorb,
        totalCostNetto: 0.00,
        totalCostBrutto: 0.00
    });

    const dispatch = useDispatch();

    const formInput = [
        {
            label: "Vorname",
            type: "text",
            name: "firstname"
        },
        {
            label: "Nachname",
            type: "text",
            name: "surname"
        },
        {
            label: "Postleitzahl",
            type: "text",
            name: "plz"
        },
        {
            label: "Straße",
            type: "text",
            name: "street"
        },
        {
            label: "Geburtsdatum",
            type: "date",
            name: "bday"
        },
        {
            label: "Email oder Telefon",
            type: "email",
            name: "contact"
        }
    ]

    const priceList = [
        {
            segment: "Transport",
            price: 10, //10€ Grundpreis + 0.30€ pro km
            priceAdding: " + 0.30€ pro km",
            image: beschaffung,
            included: [
                {
                    id: 1,
                    task: "Einzelfahrten",
                    price: 10,
                    priceAdding: " + 0.30€ pro km"
                },
                {
                    id: 2,
                    task: "Hin- und Rückfahrten",
                    price: 10,
                    priceAdding: " + 0.30€ pro km"
                },
                {
                    id: 3,
                    task: "Kurierdienste",
                    price: 10,
                    priceAdding: " + 0.30€ pro km"
                },
                {
                    id: 4,
                    task: "Einkäufe",
                    price: 10,
                    priceAdding: " + 0.30€ pro km"
                },
                {
                    id: 5,
                    task: "Fahrten zu Veranstaltungen",
                    price: 10,
                    priceAdding: " + 0.30€ pro km"
                },
                {
                    id: 6,
                    task: "Dienstleistungsfahrten",
                    price: 10,
                    priceAdding: " + 0.30€ pro km"
                },
                {
                    id: 7,
                    task: "Kirchenbesuche",
                    price: 10,
                    priceAdding: " + 0.30€ pro km"
                },
                {
                    id: 8,
                    task: "Familienbesuche",
                    price: 10,
                    priceAdding: " + 0.30€ pro km"
                },
                {
                    id: 9,
                    task: "Bürokatrische Unternehmungen",
                    price: 10,
                    priceAdding: " + 0.30€ pro km"
                },
                {
                    id: 10,
                    task: "Abholungen",
                    price: 10,
                    priceAdding: " + 0.30€ pro km"
                }
            ]
        },
        {
            segment: "Soziale Interaktionen",
            price: 20, //20€ pro Stunde
            priceAdding: "pro Stunde",
            image: sozialeInteraktion,
            included: [
                {
                    id: 11,
                    task: "Gesellschaftlicher Austausch: (Kaffeklatsch)",
                    price: 20,
                    priceAdding: "pro Stunde"
                },
                {
                    id: 12,
                    task: "Aktivitäten: (Wandern, Ausflüge, Theaterbesuche, etc.)",
                    price: 20,
                    priceAdding: "pro Stunde"
                },
                {
                    id: 13,
                    task: "Kochen: gemeinsames Kochen und Essen in Gesellschaft",
                    price: 20,
                    priceAdding: "pro Stunde"
                },
                {
                    id: 14,
                    task: "Spaziergänge",
                    price: 20,
                    priceAdding: "pro Stunde"
                },
                {
                    id: 15,
                    task: "Spiele und Unterhaltung",
                    price: 20,
                    priceAdding: "pro Stunde"
                },
                {
                    id: 16,
                    task: "Gemeinsames Lesen oder Vorlesen",
                    price: 20,
                    priceAdding: "pro Stunde"
                },
                {
                    id: 17,
                    task: "Gedächtnisspiele und -aktivitäten (Kreuzworträtsel, Memory etc.)",
                    price: 20,
                    priceAdding: "pro Stunde"
                }
            ]
        },
        {
            segment: "Begleitung",
            price: 20, //20€ pro Stunde
            priceAdding: "pro Stunde",
            image: begleitung,
            included: [
                {
                    id: 18,
                    task: "Kulturelle Erlebnisse gemeinsam genießen (z.B. Kino)",
                    price: 20,
                    priceAdding: "pro Stunde"
                },
                {
                    id: 19,
                    task: "Gemeinsame Ausflüge",
                    price: 20,
                    priceAdding: "pro Stunde"
                },
                {
                    id: 20,
                    task: "Gemeinsame Arztbesuche",
                    price: 20,
                    priceAdding: "pro Stunde"
                },
                {
                    id: 21,
                    task: "Gemeinsamer Einkaufsbummel",
                    price: 20,
                    priceAdding: "pro Stunde"
                },
                {
                    id: 22,
                    task: "Handwerkliche Aktivitäten: (Töpfern, Malen, Stricken, uvm.)",
                    price: 20,
                    priceAdding: "pro Stunde"
                }
            ]
        },
        {
            segment: "Tierpflege",
            price: 20, //20€ pro Stunde
            priceAdding: "pro Stunde",
            image: tierpflege,
            included: [
                {
                    id: 23,
                    task: "Hundespaziergang",
                    price: 20,
                    priceAdding: "pro Stunde"
                },
                {
                    id: 24,
                    task: "Fütterung",
                    price: 20,
                    priceAdding: "pro Stunde"
                },
                {
                    id: 25,
                    task: "Gemeinsame Tierarzttermine",
                    price: 20,
                    priceAdding: "pro Stunde"
                },
                {
                    id: 26,
                    task: "Haustiersitting",
                    price: 20,
                    priceAdding: "pro Stunde"
                },
                {
                    id: 27,
                    task: "Spielzeit mit Haustieren",
                    price: 20,
                    priceAdding: "pro Stunde"
                }

            ]
        },
        {
            segment: "Haushalt",
            price: 20, //20€ pro Stunde
            priceAdding: "pro Stunde",
            image: haushalt,
            included: [
                {
                    id: 28,
                    task: "Tägliche Hausarbeiten",
                    price: 20,
                    priceAdding: "pro Stunde"
                },
                {
                    id: 29,
                    task: "Bügelservice",
                    price: 20,
                    priceAdding: "pro Stunde"
                },
                {
                    id: 30,
                    task: "Unterstützende Garten- und Pflanzenarbeit",
                    price: 20,
                    priceAdding: "pro Stunde"
                },
                {
                    id: 31,
                    task: "Staubsaugen",
                    price: 20,
                    priceAdding: "pro Stunde"
                },
                {
                    id: 32,
                    task: "Wischen",
                    price: 20,
                    priceAdding: "pro Stunde"
                },
                {
                    id: 33,
                    task: "Entrümpelung",
                    price: 20,
                    priceAdding: "pro Stunde"
                },
                {
                    id: 34,
                    task: "Kochservice",
                    price: 20,
                    priceAdding: "pro Stunde"
                }

            ]
        },
        {
            segment: "Botengänge/ Beschaffungen",
            image: technik,
            included: [
                {
                    id: 35,
                    task: "Medikamentenbeschaffung",
                    price: 10,
                    priceAdding: " + Produktpreis"
                },
                {
                    id: 36,
                    task: "Blumengestecke",
                    price: 10,
                    priceAdding: " + Produktpreis"
                },
                {
                    id: 37,
                    task: "Briefversand",
                    price: 5,
                    priceAdding: " + Briefporto"
                },
                {
                    id: 38,
                    task: "Paketdienst (Abholen, Versenden etc.)",
                    price: 10,
                    priceAdding: " + Versandkosten"
                },
                {
                    id: 39,
                    task: "Backwarenbesorgung/ Frühstück",
                    price: 10,
                    priceAdding: " + Produktpreis"
                },
                {
                    id: 40,
                    task: "Andere Besorgungen (Bücher, Pakete, Kleidung aus der Reinigung etc)",
                    price: 20,
                    priceAdding: " + Produktpreis"
                }
            ]
        }
    ];


    function handleHourChange(event, segment) {
        const newHours = event.target.value;
    
        setWarenkorb(prevWarenkorb => {
            const existingItemIndex = prevWarenkorb.findIndex(item => item.segment === segment);
    
            if (existingItemIndex !== -1) {
                // If the segment already exists, update the existing item
                const updatedWarenkorb = [...prevWarenkorb];
                updatedWarenkorb[existingItemIndex] = {
                    ...updatedWarenkorb[existingItemIndex],
                    hours: newHours
                };
                console.log(warenkorb);

                return updatedWarenkorb;
            } else {
                const price = priceList.find(item => item.segment === segment)?.price;
                console.log(warenkorb);

                return [
                    ...prevWarenkorb,
                    {
                        segment,
                        price,
                        hours: newHours
                    }
                ];
            }
        });
    
    }
    
    
    const handleBeginTimeChange = (time, segment) => {
        const formattedTime = dayjs(time).format('HH:mm');  // Formatierung in das deutsche Format
    
        setWarenkorb(prevWarenkorb => {
            // Überprüfe, ob das Segment bereits im Warenkorb existiert
            const updatedWarenkorb = prevWarenkorb.map(item => {
                if (item.segment === segment) {
                    return {
                        ...item,
                        beginTime: formattedTime
                    };
                }
                return item;
            });
    
            // Wenn das Segment nicht im Warenkorb existiert, füge es hinzu
            if (updatedWarenkorb.every(item => item.segment !== segment)) {
                const price = priceList.find(item => item.segment === segment)?.price;
                updatedWarenkorb.push({
                    segment,
                    price,
                    beginTime: formattedTime
                });
            }
    
            return updatedWarenkorb;
        });
    };
    
    const handleWishDayChange = (date, segment) => {
        const formattedDate = dayjs(date).format('DD/MM/YYYY');  // Formatierung in das deutsche Format
    
        setWarenkorb(prevWarenkorb => {
            // Überprüfe, ob das Segment bereits im Warenkorb existiert
            const updatedWarenkorb = prevWarenkorb.map(item => {
                if (item.segment === segment) {
                    return {
                        ...item,
                        wishDay: formattedDate
                    };
                }
                return item;
            });
    
            // Wenn das Segment nicht im Warenkorb existiert, füge es hinzu
            if (updatedWarenkorb.every(item => item.segment !== segment)) {
                const price = priceList.find(item => item.segment === segment)?.price;
                updatedWarenkorb.push({
                    segment,
                    price,
                    wishDay: formattedDate
                });
            }
    
            return updatedWarenkorb;
        });
    };
    

    function addToShoppingList(event, segment, included) {
        setWarenkorb(prevWarenkorb => {
            // Find the existing item index
            const existingItemIndex = prevWarenkorb.findIndex(item => item.segment === segment);



            if (event.detail.checked) {
                if(segment !== "Botengänge/ Beschaffungen"){
                    // For other segments
                    if (existingItemIndex === -1) {
                        // If the segment does not exist, add it
                        return [
                            ...prevWarenkorb,
                            {
                                segment,
                                tasks: [included.task],
                                price: included.price,
                                priceAdding: included.priceAdding,
                                hours: 1
                            }
                        ];
                    } else {
                        // If the segment exists, update it
                        return prevWarenkorb.map(item => {
                            if (item.segment === segment) {
                                return {
                                    ...item,
                                    tasks: [...item.tasks, included.task],
                                    price: included.price,
                                    priceAdding: included.priceAdding,
                                };
                            }
                            return item;
                        });
                    }
                }else{
                    console.log("-----------Botengänge/ Beschaffungen-----------")
                    // For Botengänge/ Beschaffungen
                    if (existingItemIndex === -1) {
                        // If the segment does not exist, add it
                        return [
                            ...prevWarenkorb,
                            {
                                segment,
                                tasks: [included.task],
                                price: included.price,
                                priceAdding: included.priceAdding
                            }
                        ];
                    } else {
                        // If the segment exists, update it
                        return prevWarenkorb.map(item => {
                            if (item.segment === segment) {
                                return {
                                    ...item,
                                    tasks: [...item.tasks, included.task],
                                    price: item.price + included.price,
                                    priceAdding: included.priceAdding
                                };
                            }
                            return item;
                        });
                    }
                }
            } else {
                if(segment === "Botengänge/ Beschaffungen"){
                    const updatedWarenkorb = [...prevWarenkorb];
                    const tasks = updatedWarenkorb[existingItemIndex].tasks.filter(task => task !== included.task);
                    const oldPrice = updatedWarenkorb[existingItemIndex].price;
                    updatedWarenkorb[existingItemIndex] = {
                        ...updatedWarenkorb[existingItemIndex],
                        tasks: tasks,
                        price: oldPrice - included.price
                    };
                    return updatedWarenkorb.filter(item => item.tasks.length > 0);
                }
                else{
                // If the item is unchecked, remove the task from the segment
                const updatedWarenkorb = [...prevWarenkorb];
                const tasks = updatedWarenkorb[existingItemIndex].tasks.filter(task => task !== included.task);
                updatedWarenkorb[existingItemIndex] = {
                    ...updatedWarenkorb[existingItemIndex],
                    tasks: tasks
                };
                // Filtere leere Segmente aus dem Warenkorb
                return updatedWarenkorb.filter(item => item.tasks.length > 0);
                }
            }

        });
    }
    


    function handleChangeMessage(e) {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value
        });
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        console.log(formData)
        // Überprüfe, ob zu jedem Segment ein Wunschdatum und ein Startzeitpunkt vorhanden sind
        const missingFields = warenkorb.filter(item => {
            // Überprüfe, ob wishDay oder beginTime nicht definiert oder "Invalid Date" sind
            const isWishDayValid = item.wishDay && dayjs(item.wishDay, 'DD/MM/YYYY', true).isValid();
            const isBeginTimeValid = item.beginTime && dayjs(item.beginTime, 'HH:mm', true).isValid();

            return !item.wishDay || !item.beginTime || !isWishDayValid || !isBeginTimeValid;
        });

        if (missingFields.length > 0) {
            // Erstelle eine Fehlermeldung mit den fehlenden Segmenten und welches Feld fehlt oder ungültig ist
            const missingMessages = missingFields.map(item => {
                let missingMessage = `Für das Segment '${item.segment}' fehlt`;
                if (!item.wishDay || !dayjs(item.wishDay, 'DD/MM/YYYY', true).isValid()) {
                    missingMessage += ` das Wunschdatum`;
                }
                if (!item.beginTime || !dayjs(item.beginTime, 'HH:mm', true).isValid()) {
                    missingMessage += `${!item.wishDay || !dayjs(item.wishDay, 'DD/MM/YYYY', true).isValid() ? ' und' : ''} den Beginnzeitpunkt`;
                }
                missingMessage += '.';
                return missingMessage;
            }).join('\n');

            alert(`Bitte füllen Sie alle erforderlichen Felder aus:
${missingMessages}`);
            return;
        }


        formData['warenkorb'] = warenkorb;

        try {
            const response = await fetch('http://localhost:3002/send-email', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*'
                },
                body: JSON.stringify(formData)
            });

            if (response.ok) {
                alert('Vielen Dank! Wir werden uns schnellstmöglich bei Ihnen melden. :)');
                console.log('Email sent successfully');
            } else {
                const error = await response.text();
                console.error('Error sending email:', error);
            }
        } catch (error) {
            console.error('Error sending email:', error);
        }
    };
        


    function totalCost() {

        let total = 0.00;
        let oldSegment = null;
        for (const item of warenkorb) {
            if (item && typeof item.price === 'number' && item.segment) {
                if (oldSegment !== item.segment || item.segment === "Botengänge/ Beschaffungen") {
                    if(item.segment === "Botengänge/ Beschaffungen"){
                        total += item.price;
                    }else{
                        total += (item.price * item.hours);
                    }
                    oldSegment = item.segment;
                }
            }
        }
        formData['totalCostNetto'] = parseFloat(total.toFixed(2));
        formData['totalCostBrutto'] = parseFloat((total * 1.19).toFixed(2));



        return parseFloat(total.toFixed(2)); 
    }
    

    const groupTasksBySegment = () => {
        const groupedTasks = {};
        for (const item of warenkorb) {
            if (item && item.segment) {
                if (!groupedTasks[item.segment]) {
                    groupedTasks[item.segment] = [];
                }
                groupedTasks[item.segment].push(item);
            }
        }
        return groupedTasks;
    };

    const cost = totalCost();
    const quantity = warenkorb.length;


    const scrollShoppingCart = () => shoppingCart.current.scrollIntoView({ behavior: "smooth" });


    useEffect(()=>{
        console.log("---------Setzen des Warenkorbs:" + warenkorb)
        dispatch(setShoppingCart(warenkorb))
    }, [warenkorb, dispatch])

    return (
        <IonPage className="page-design">
            <IonContent className="background">
                <SpaceForHeader />
                <IonGrid ref = {shoppingCart} style = {{margin: 0, padding:0}} onClick={() => scrollShoppingCart()}>
                    <ShoppingCartRow cost={cost} quantity = {quantity} />
                </IonGrid>
                <IonGrid className="content">
                <Divider> <ThemedText variant="h1-dark">Hilfsleistung</ThemedText></Divider>

                    <IonGrid className = "grid-hilfstext">
                        <p>Diese Auswahl soll uns helfen zu verstehen, was Sie brauchen!</p>
                        <p>Sollten Sie Tätigkeiten benötigen, die hier nicht ausgewählt werden können, </p>
                        <p><strong className="p-contact"><Link className="no-decoration" to="/contact"> schreiben Sie uns einfach an</Link></strong></p>
                        <p>Wir helfen gerne!</p>
                    </IonGrid>
                    <Divider> <ThemedText variant="h2-dark">PREISE</ThemedText></Divider>


                    <IonRow className="row-items-price">
                        {priceList.map((item, index) => (
                            <IonCol sizeXl="3.7" sizeXs="12" key={index} className = "sixColPriceSegStyle"
                                style={{
                                    backgroundImage: `url(${item.image})`,
                                    backgroundSize: 'cover',
                                }}>

                                <IonGrid className="grid-col-price">
                                    <IonGrid
                                        style={{
                                            cursor: 'pointer',
                                        }}
                                    >
                                        <Divider style={{ borderBottom: '1px solid white' }}><p className="text-headline" >{item.segment}</p></Divider>
                                        {item.price &&
                                            <p className="text-price">{item.price} € {item.priceAdding}</p>
                                        }
                                    </IonGrid>
                                    {item.included.map((included, includedIndex) => (
                                        <IonGrid key={includedIndex} style={{
                                            maxHeight: '1000px',
                                            overflow: 'hidden',
                                            transition: 'opacity 1s ease-in-out, max-height 1s ease-in-out',
                                            padding: '0px',
                                            margin: "0px"

                                        }} >
                                            <form>
                                                <IonCheckbox className = "ioncheckbox" labelPlacement='end' onIonChange={(event) => addToShoppingList(event, item.segment, included)}>
                                                    <p className="ioncheckbox-included">{included.task} </p>
                                                    {included.id >= 35 &&
                                                        <>
                                                            <p className="ioncheckbox-included-mini">{included.price} € {included.priceAdding}</p>
                                                        </>
                                                    }
                                                </IonCheckbox>
                                            </form>
                                        </IonGrid>
                                    ))}
                                </IonGrid>
                            </IonCol>
                        ))}
                    </IonRow>
                    <IonRow size="12" size-md="12">
                        <IonGrid className="row-card-two">
                        <p className="titleWarenkorb">Warenkorb </p>
                            <form onSubmit={handleSubmit}>
                                <IonRow className="rowWarenkorb">
                                    <IonCol size="7.5" sizeLg = "7.5" sizeXs='12' style={{ padding: 0 }} ref={shoppingCart}>
                                                    <>
                                            {Object.entries(groupTasksBySegment()).map(([segment, tasks], index) => (
                                                <IonGrid className="product-in-shoppingCart" key={index}>
                                                    <IonRow className="grid-segment-title">
                                                        <p className="segment-title-shoppingcart">{segment}</p>
                                                        {segment !== "Botengänge/ Beschaffungen" && (
                                                            <p className="segment-title-shoppingcart"> - {tasks[0].price} € pro Stunde</p>
                                                        )}
                                                    </IonRow>

                                                    <IonRow>
                                                        <IonCol sizeXs="12" sizeXl="4" sizeMd="4" sizeLg="4" style={{ display: "flex", alignItems: "center" }}>
                                                            <img src={priceList.find(item => item.segment === segment).image} alt={segment} className="image-segment" />
                                                        </IonCol>
                                                        <IonCol sizeXs="12" sizeMd="8" sizeLg="8">
                                                            {segment !== "Botengänge/ Beschaffungen" &&
                                                                <>
                                                                    <IonRow className="row-select-hour" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                                        <p className="p-stundenzahl">Stundenzahl: </p>
                                                                        <select key={index} className="select-hours" onChange={(e) => handleHourChange(e, segment)} value={tasks.hours}>
                                                                            {[...Array(25).keys()].map(value =>
                                                                                value !== 0 && <option key={value} value={value}>{value} Stunden</option>
                                                                            )}
                                                                        </select>
                                                                    </IonRow>
                                                                </>
                                                            }
<IonRow>

                                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                <IonCol sizeSm='6' sizeXs="12" style={{ padding: "10px", display: "flex", justifyContent: "center" }}>
                                                                    <DatePicker
                                                                        className="datetimepicker"
                                                                        id="wishday"
                                                                        label="Wunschdatum"
                                                                        format='DD/MM/YYYY'
                                                                        value={wishDay}
                                                                        onChange={(e) => handleWishDayChange(e, segment)}
                                                                        required

                                                                    />
                                                                </IonCol>

                                                                <IonCol sizeSm='6' sizeXs="12" style={{ padding: "10px", display: "flex", justifyContent: "center" }}>
                                                                    <TimePicker
                                                                        className='datetimepicker'
                                                                        id="begin"
                                                                        clearable
                                                                        ampm={false}
                                                                        required
                                                                        label="Von"
                                                                        value={beginTime}
                                                                        onChange={(e) => handleBeginTimeChange(e, segment)}
                                                                    />
                                                                    {/* <TimePicker
                                                                                    className='datetimepicker'
                                                                                    id="end"
                                                                                    clearable
                                                                                    ampm={false}
                                                                                    required
                                                                                    label="Bis"
                                                                                /> */}
                                                                </IonCol>
                                                            </LocalizationProvider>
                                                            </IonRow>

                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow className='row-voraussichtlicheTätigkeiten'>
                                                        <IonCol size="12">
                                                            <p className='p-title-vorausTätigkeiten'>Voraussichtliche Tätigkeiten: </p>
                                                        </IonCol>

                                                        {tasks.map((item, itemIndex) => (
                                                            <>
                                                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                                    {item.tasks && item.tasks.map((task, index) => (
                                                                        <li key={index}>
                                                                            <p>{task}</p>
                                                                        </li>
                                                                    ))}
                                                                    {segment === "Botengänge/ Beschaffungen" && (
                                                                        <>
                                                                            <IonCol size='1.5'>
                                                                                <ThemedText variant="p-bright-mini">{item.price} €</ThemedText>
                                                                            </IonCol>
                                                                            <IonCol size='4'>
                                                                                <ThemedText variant="p-bright-mini">{item.priceAdding}</ThemedText>
                                                                            </IonCol>
                                                                        </>
                                                                    )}
                                                            </div>
                                                            </>
                                                        ))}
                                                    </IonRow>
                                                            </IonGrid>
                                                        ))}
                                                </>
                                    </IonCol>
                                    <IonCol size="4.5" sizeLg='4.5' sizeXs='12' className="shoppingCartRightCol">
                                    <Divider className = "divider-kontaktdaten">Buchende Hilfstätigkeiten</Divider>
                                        <IonRow style={{ display: "flex", flexDirection: "row", marginBottom: "20px" }}>
                                            {warenkorb.map((item, index) => (
                                                // Prüfen, ob das aktuelle Segment bereits in vorherigen Elementen gerendert wurde
                                                index === 0 || warenkorb.slice(0, index).every(prevItem => prevItem.segment !== item.segment) ? (
                                                    <>
                                                        {
                                                            item.segment !== "Botengänge/ Beschaffungen" ?
                                                                <>
                                                                    <IonCol sizeLg="7" sizeXs='12' key={index}>
                                                                        {item.hours &&
                                                                            item.hours > 1 ?
                                                                            <p className="shoppingCart-segment">{item.segment}: <br />{item.hours} Stunden</p> :
                                                                            <p className="shoppingCart-segment">{item.segment}: <br />{item.hours} Stunde</p>
                                                                        }
                                                                    </IonCol>
                                                                    <IonCol sizeLg="5" sizeXs='12' style={{ display: "flex", justifyContent: "flex-end" }} key={index}>
                                                                        <p className="shoppingCart-hours">{(item.hours * 20).toFixed(2)} €</p>
                                                                    </IonCol>
                                                                    <IonItemDivider />

                                                                    </>
                                                                :

                                                                <>
                                                                    <IonCol sizeLg="7" sizeXs='12' key={index}>
                                                                        <p className="shoppingCart-segment">{item.segment}: <br /></p>
                                                                        {item.tasks && item.tasks.map((task, index) => (
                                                                            <p key={index} className="shoppingCart-task-botengang">{task}</p>
                                                                        ))}
                                                                    </IonCol>
                                                                    <IonCol sizeLg="5" sizeXs='12' style={{ display: "flex", justifyContent: "flex-end" }} key={index}>
                                                                        <p className="shoppingCart-hours">{(item.price).toFixed(2)} €</p>
                                                                    </IonCol>
                                                                    <IonItemDivider />
                                                                    </>


                                                        }
                                                    </>
                                                ) : null
                                            ))}
                                        </IonRow>

                                        <IonRow style={{ display: "flex", flexDirection: "row", marginBottom: "20px" }}>
                                            
                                            
                                            <IonCol size="7" >
                                                <p className="shoppingCart-results">Netto Preis</p>
                                            </IonCol>
                                            <IonCol size="5"  style={{ display: "flex", justifyContent: "flex-end" }}>
                                                <p className="shoppingCart-results">{totalCost().toFixed(2)} €</p>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow style={{ display: "flex", flexDirection: "row", marginBottom: "20px" }}>
                                        <IonCol sizeLg="7" >
                                                <p className="shoppingCart-results">Mehrwertsteuer</p>
                                            </IonCol>
                                            <IonCol sizeLg="5" style = {{display:"flex", justifyContent:"flex-end"}}>
                                            <p className = "shoppingCart-results">+ {(totalCost()* 0.19).toFixed(2)} €</p>
                                            </IonCol>
                                        </IonRow>

                                        <IonGrid className = "divider"/>

                                        <IonRow style={{ width: "100%", bgColor: "black", height: "5px" }} />

                                        <IonRow style={{ display: "flex", flexDirection: "row", marginBottom: "20px" }}>
                                        <IonCol sizeLg="7" >
                                                <p className="shoppingCart-results" style={{ fontWeight: "700" }}>Gesamtpreis</p>
                                            </IonCol>
                                            <br />
                                            <IonCol sizeLg="5"  style={{ display: "flex", justifyContent: "flex-end" }}>
                                                <p className="shoppingCart-results" style={{ fontWeight: "700" }}>{(totalCost() + totalCost() * 19 / 100).toFixed(2)} €</p>
                                            </IonCol>
                                            <br />
                                        </IonRow>


                                        <br/>
                                        <IonRow style = {{padding: 0, margin:0}}>
                                            <IonCol sizeLg="12" sizeMd="12" sizeXs='12' >
                                                <Divider className = "divider-kontaktdaten">Kontaktdaten</Divider>
                                                {formInput.map((input, index) => (
                                                    index % 2 === 0 && (
                                                        <IonRow key={index} >
                                                            {formInput.slice(index, index + 2).map((input, subIndex) => (
                                                                <>
                                                                    <IonCol size="6" className="col-input-form" style={{ padding: "10px" }}>
                                                                    <input
                                                                    className="input-design"
                                                                    id={input.name}
                                                                    label={input.label}
                                                                    variant="outlined"
                                                                    type={input.name === "bday" ? "text" : input.type}
                                                                    placeholder={input.label}
                                                                    onChange={handleChangeMessage}
                                                                    onFocus={input.name === "bday" ? (e) => e.target.type = "date" : null}
                                                                    required
                                                                />


                                                            </IonCol>
                                                        </>
                                                    ))}
                                                </IonRow>
                                            )
                                        ))}
                                    </IonCol>
                                    <IonCol sizeLg = "12" sizeMd = "12" sizeXs='12'>
                                    <Divider className = "divider-kontaktdaten">Nachricht</Divider>
                                        <IonRow style={{ padding: "0",margin:0, display: "flex", justifyContent: "center", alignItems: "center"}}>
                                            <QuestionmarkModal text = {messageModalText} title =  {messageModalTitle} />
                                            <textarea 
                                            className="input-design message-input" 
                                            onChange={handleChangeMessage} 
                                            placeholder=
                                            "Genauere Informationen zu deinem Auftrag: 
                                            - Wann soll der Auftrag erledigt werden?
                                            - Was genau soll erledigt werden?
                                            - Wo brauchen sie die Hilfe?
                                            - Muss auf etwas besonders geachtet werden?
                                            - Benötigen wir Hilfsutensilien?
                                            - Sonstige Informationen
                                            "
                                            id="message" 
                                            name="message" 
                                            cols="50"
                                            onFocus={(e) => e.target.style.color = "black"}
                                            required
                                            />
                                        </IonRow>
                                    </IonCol>

                                </IonRow>
                                <IonRow style={{ display: "flex", justifyContent: "center", padding: "50px", paddingTop: "20px"}}>
                                            <IonButton style={{ width: "70%" }} type="submit" disabled={false}>Absenden</IonButton>
                                        </IonRow>
                                    </IonCol>
                                </IonRow>
                                
                                </form>
                        </IonGrid>
                    </IonRow>
                </IonGrid>
                {/* INDIVIDUALIZED FOOTER! */}
                <IonGrid className='footerStyle'>
                    <IonRow style={{ justifyContent: "flex-end" }}>
                        <IonCol>
                            <Link to="/datenschutz" className="link">
                                Datenschutz
                            </Link>
                        </IonCol>
                        <IonCol>
                            <Link to="/impressum" className="link" >
                                Impressum
                            </Link>
                        </IonCol>
                    </IonRow>
                </IonGrid>

            </IonContent>
        </IonPage>
    );
}


// TODO: Uhrzeit + datum einfügen in anfrage erstellen 