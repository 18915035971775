/*
* Creates a Redux store that holds the complete state tree of our app.
* Automatically serializes the state and persists it to local storage.
*/
import { configureStore } from '@reduxjs/toolkit';
import userReducer from './slices/userSlice'; // Passe den Pfad entsprechend deiner Struktur an
import coordinateReducer from './slices/coordinateSlice'; // Passe den Pfad entsprechend deiner Struktur an
import logoReducer from './slices/logoSlice'; // Passe den Pfad entsprechend deiner Struktur an
import shoppingCartSlice from './slices/shoppingCartSlice';

const persistedUser = JSON.parse(localStorage.getItem('user')) || { 
  token: null,
};

const store = configureStore({
  reducer: {
    user: userReducer,
    coordinate: coordinateReducer,
    logo: logoReducer,
    shoppingCart: shoppingCartSlice
  },
  preloadedState: { //Initialen Zustand für Store definieren
    user:persistedUser,
  }
});

store.subscribe(() => {
  localStorage.setItem('user', JSON.stringify(store.getState().user));
});

export default store;
