import React, { useRef } from 'react';
import {  Divider  } from '@mui/material';
//Images

import { useDispatch } from 'react-redux';
import {  IonButton, IonText } from '@ionic/react';

import "../HeaderBar.css";
import "../../../CSS/globalStyles.css";

import { IonListHeader, IonList, IonItem, IonIcon,  IonToolbar, IonTitle, IonContent, IonMenu, } from '@ionic/react';

import { setUser } from '../../../slices/userSlice';

//ICONS
import iconMedal from "../assets/medal.svg";
import iconTrophy from "../assets/trophy.svg";
import iconCheckmark from "../assets/checkmark-circle.svg";
import iconAlert from "../assets/alert.svg";
import iconPaperPlane from "../assets/paper-plane.svg";
import iconPencil from "../assets/pencil.svg";
import iconSettings from "../assets/settings.svg";
import iconHeartCircle from "../assets/heart-circle.svg";
import iconConstruct from "../assets/construct.svg";
import iconHome from "../assets/home.svg";

import { useEffect, useState } from 'react';

import "../HeaderBar.css";


export default function BurgerMenue() {

    const menuAccountRef = useRef(null);
    const dispatch = useDispatch();

    const [smallDevice, setSmallDevice] = useState(window.innerWidth < 768);

    useEffect(() => {
        function handleResize() {
            if (window.innerWidth < 768) {
                setSmallDevice(true);
            } else {
                setSmallDevice(false);
            }
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [setSmallDevice]);


    console.log(smallDevice)

    function LogOut(){
        localStorage.removeItem('token');
        dispatch(setUser(null));
        menuAccountRef.current.close();
    
      }



    const menuAccount = document.getElementById('ion-menu-account');
    console.log(menuAccount)


    return (
        <>
            <IonContent style={{ display: "flex", alignItems: "center", height: "100px"}}>
                    <div className="burgerMenue" id="main-content">
                        <ion-buttons>
                            <ion-menu-button />
                        </ion-buttons>
                    </div>

                <IonMenu side="end" content-id="main-content" className='headerMenue'>
                    <IonToolbar >
                        <IonTitle className="ionTitle-menu">Menü</IonTitle>
                </IonToolbar>
                <Divider />
                <IonContent>
                    <IonList>
                        <IonItem routerLink="/">
                            <IonText>Startseite</IonText>
                        </IonItem>
                        <IonItem routerLink="/portfolio">
                            <IonText>Anfragen und Preise</IonText>
                        </IonItem>
                        <IonItem routerLink="/contact">
                            <IonText>Kontakt</IonText>
                        </IonItem>
                    </IonList>
                </IonContent>
            </IonMenu>
        </IonContent>
            <IonMenu ref={menuAccountRef} className="menuAccount" >
                <IonToolbar >
                    <IonTitle className="ionTitle-menu">Menu Content</IonTitle>
                </IonToolbar>
                <Divider />
                <IonContent>
                    <IonList>
                        <IonListHeader className="ionListHeader-name">Hilfsaufträge</IonListHeader >
                        <IonItem routerLink="/account/accepted-helptask">
                            <IonIcon icon={iconAlert}></IonIcon>
                            <IonText>Akzeptierte Aufträge</IonText>
                        </IonItem>
                        <IonItem routerLink="/account/solved-helptask">
                            <IonIcon icon={iconCheckmark}></IonIcon>
                            <IonText>Solved Helptasks</IonText>
                        </IonItem>
                        <IonItem routerLink="/account/created-helptask">
                            <IonIcon icon={iconPencil}></IonIcon>
                            <IonText>Created Helptasks</IonText>
                        </IonItem>
                    </IonList>
                    <IonList>
                        <IonListHeader className="ionListHeader-name" >Für Deine Hilfe </IonListHeader >
                        <IonItem routerLink="/account/history">
                            <IonIcon icon={iconTrophy}></IonIcon>
                            <IonText>Belohnungen</IonText>
                        </IonItem>
                    </IonList>
                    <IonList>
                        <IonListHeader className="ionListHeader-name" >Social</IonListHeader >
                        <IonItem routerLink="/account/history">
                            <IonIcon icon={iconPaperPlane}></IonIcon>
                            <IonText>Messages</IonText>
                        </IonItem>
                        <IonItem routerLink="/account/history">
                            <IonIcon icon={iconMedal}></IonIcon>
                            <IonText>Freunde</IonText>
                        </IonItem>
                    </IonList>
                    <IonList>
                        <IonListHeader className="ionListHeader-name">Sonstiges</IonListHeader>
                        <IonItem routerLink="/account">
                            <IonIcon icon={iconHome}></IonIcon>
                            <IonText>Mein Bereich</IonText>
                        </IonItem>
                        <IonItem routerLink="/account/history">
                            <IonIcon icon={iconSettings}></IonIcon>
                            <IonText>Einstellungen</IonText>
                        </IonItem>
                        <IonItem routerLink="/account/history">
                            <IonIcon icon={iconConstruct}></IonIcon>
                            <IonText>Fehler melden</IonText>
                        </IonItem>
                        <IonItem routerLink="/account/history">
                            <IonIcon icon={iconHeartCircle}></IonIcon>
                            <IonText>Feedback</IonText>
                        </IonItem>
                    </IonList>
                    <IonButton onClick={LogOut} style={{ display: "flex", justifyContent: "center", padding: 65 }}>
                        <IonText >Abmelden</IonText>
                    </IonButton>
                </IonContent>
            </IonMenu>
        </>
    );

}
