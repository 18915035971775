import React  from 'react';
import { Link } from "react-router-dom";
//Images

import { IonGrid, IonRow, IonCol } from '@ionic/react';

import "../HeaderBar.css";
import "../../../CSS/globalStyles.css";

import {IonPage} from '@ionic/react';

// import { setUser } from '../../../slices/userSlice'; 
import iconShoppingCart from "../../../SVG/Icon/shoppingCart.png"

// //ICONS
// import iconMedal from "../assets/medal.svg";
// import iconTrophy from "../assets/trophy.svg";
// import iconCheckmark from "../assets/checkmark-circle.svg";
// import iconAlert from "../assets/alert.svg";
// import iconPaperPlane from "../assets/paper-plane.svg";
// import iconPencil from "../assets/pencil.svg";
// import iconSettings from "../assets/settings.svg";
// import iconHeartCircle from "../assets/heart-circle.svg";
// import iconConstruct from "../assets/construct.svg";
// import iconHome from "../assets/home.svg";
// import ThemedText from '../../ThemedText';

// import { useEffect } from 'react';

export default function HeaderContent() {
  // const token = useSelector((state) => state.user.token);

  // const menuAccountRef = useRef(null);
  // const dispatch = useDispatch();

  // const [setSmallDevice] = useState(null);

  // useEffect(() => {
  //   function handleResize() {
  //     if (window.innerWidth < 768) {
  //       setSmallDevice(true);
  //     } else {
  //       setSmallDevice(false);
  //     }
  //   }
  //   window.addEventListener("resize", handleResize);
  //   return () => window.removeEventListener("resize", handleResize);
  // }, [setSmallDevice]);
  

  const headerLinks = [
    {
      title: "Home",
      path: "/",
    },
    {
      title: "Anfragen und Preise",
      path: "/portfolio",
    },
    {
      title: "Kontakt",
      path: "/contact",
    },
    {
      title: "Warenkorb",
      path: "/portfolio",
    },
    // {
    //   title: "News",
    //   path: "/news",
    // },
    // {
    //   title: "Helfen",
    //   path: "/helptasks",
    // },
    // {
    //   title: "Auftrag erstellen",
    //   path: "/create-helptask",
    // },

  ];



  // function menuAccountShow(){
  //   menuAccountRef.current.open();
  // }
  // const user = useSelector((state) => state.user.currentUser);
  // const userpic = user ? user.userPic : "link-zum-bild.jpg";

  const menuAccount = document.getElementById('ion-menu-account');
  console.log(menuAccount)

  // function LogOut(){
  //   localStorage.removeItem('token');
  //   dispatch(setUser(null));
  //   menuAccountRef.current.close();
  // }

  return (
    <>
      
      <IonPage>
        <IonRow style={{ padding: 0, margin: 0, display: "flex", justifyContent: "flex-end", alignItems: "center", width: "100%" }}>
          <IonCol style={{ width: "50%", padding: 0, margin: 0, display: "flex", justifyContent: "flex-end", gap: "20px", paddingRight: "20px"}}>
            {headerLinks.map((link, index) => (
              <Link to={link.path} className="headerConent-link" style={{ textDecoration: "none", margin: "0 5px" }}>
                {link.title === "Warenkorb" ?
                  <IonGrid className="col-shoppingCart" >
                    <img className="img-shopping-cart" src={iconShoppingCart} alt="shopping cart" /> <p className='p-headerLinks'>Warenkorb</p>
                  </IonGrid>
                  :
                  <p className='p-headerLinks'>{link.title}</p>

                }
              </Link>
            ))}
          </IonCol>
        </IonRow>
      </IonPage>


      {/* <IonMenu ref={menuAccountRef} className="menuAccount">
        <IonToolbar >
          <IonTitle className="ionTitle-menu">Menu Content</IonTitle>
        </IonToolbar>
        <Divider />
        <IonContent>
          <IonList>
            <IonListHeader className="ionListHeader-name">Hilfsaufträge</IonListHeader >
            <IonItem routerLink="/account/accepted-helptask">
              <IonIcon icon={iconAlert}></IonIcon>
              <IonText>Akzeptierte Aufträge</IonText>
            </IonItem>
            <IonItem routerLink="/account/solved-helptask">
              <IonIcon icon={iconCheckmark}></IonIcon>
              <IonText>Solved Helptasks</IonText>
            </IonItem>
            <IonItem routerLink="/account/created-helptask">
              <IonIcon icon={iconPencil}></IonIcon>
              <IonText>Created Helptasks</IonText>
            </IonItem>
          </IonList>
          <IonList>
            <IonListHeader className="ionListHeader-name" >Für Deine Hilfe </IonListHeader >
            <IonItem routerLink="/account/history">
              <IonIcon icon={iconTrophy}></IonIcon>
              <IonText>Belohnungen</IonText>
            </IonItem>
          </IonList>
          <IonList>
            <IonListHeader className="ionListHeader-name" >Social</IonListHeader >
            <IonItem routerLink="/account/history">
              <IonIcon icon={iconPaperPlane}></IonIcon>
              <IonText>Messages</IonText>
            </IonItem>
            <IonItem routerLink="/account/history">
              <IonIcon icon={iconMedal}></IonIcon>
              <IonText>Freunde</IonText>
            </IonItem>
          </IonList>
          <IonList>
            <IonListHeader className="ionListHeader-name">Sonstiges</IonListHeader>
            <IonItem routerLink="/account">
              <IonIcon icon={iconHome}></IonIcon>
              <IonText>Mein Bereich</IonText>
            </IonItem>
            <IonItem routerLink="/account/history">
              <IonIcon icon={iconSettings}></IonIcon>
              <IonText>Einstellungen</IonText>
            </IonItem>
            <IonItem routerLink="/account/history">
              <IonIcon icon={iconConstruct}></IonIcon>
              <IonText>Fehler melden</IonText>
            </IonItem>
            <IonItem routerLink="/account/history">
              <IonIcon icon={iconHeartCircle}></IonIcon>
              <IonText>Feedback</IonText>
            </IonItem>
          </IonList>
          <IonButton onClick={LogOut} style={{ display: "flex", justifyContent: "center", padding: 65 }}>
            <IonText >Abmelden</IonText>
          </IonButton>
        </IonContent>
      </IonMenu> */}
    </>
  );
}



            /*{ 
            <ul>
            <li>
              <Link to="/">
                <ThemedText variant="header-link">Home</ThemedText>
              </Link>
            </li>
            <li>
              <Link to="/news">
                <ThemedText variant="header-link">News</ThemedText>
              </Link>
            </li>
            <li>
              <Link to="/helptasks">
                <ThemedText variant="header-link">Helfen</ThemedText></Link>
            </li>
            <li>
              <Link to="/create-helptask">
                <ThemedText variant="header-link">Auftrag erstellen</ThemedText>
              </Link>
            </li>
            <li >
              {token ?
                <>
                  <IonText onClick={menuAccountShow}>
                    <Grid className="signin-signup-button" to="/account" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textDecoration: 'none' }}>
                      <Avatar src={userpic} alt="Avatar von Benutzer" />
                      <span style={{ display: "block", textAlign: 'center' }}>
                        <ThemedText variant="header-link">
                          {user ? user.username : 'Profil'}
                          <br />
                          Menü
                        </ThemedText>
                      </span>
                    </Grid>
                  </IonText>
                </> :
                <>
                  <IonText> 
                    <Link className="signin-signup-button" to="/account" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textDecoration: 'none' }}>
                      <Avatar src={userpic} alt="Avatar von Benutzer" />
                      <span style={{ textAlign: 'center' }}>Anmelden</span>
                    </Link>
                  </IonText>
                </>
              }
            </li>
          </ul> 
          }*/