import React, { useRef } from 'react';
import { IonContent, IonPage, IonGrid, IonRow, IonCol, IonButton } from '@ionic/react';
import SpaceForHeader from "../../components/header/SpaceForHeader";

//Images 
// import personentransport from "../homePage/assets/DeepInfosKrameda/personenTransport.jpg";
// import sozialeInteraktion from "../homePage/assets/DeepInfosKrameda/sozialeInteraktion.jpg";
// import tierpflege from "../homePage/assets/DeepInfosKrameda/tierpflege.jpg";
// import begleitung from "../homePage/assets/DeepInfosKrameda/begleitung.jpg";
// import haushalt from "../homePage/assets/DeepInfosKrameda/haushalt.jpg";
// import technik from "../homePage/assets/DeepInfosKrameda/technik.jpg";
// import ThemedText from '../../components/ThemedText';

import beschaffung from "../homePage/assets/DeepInfosKrameda/beschaffung1.jpg";
import sozialeInteraktion from "../homePage/assets/DeepInfosKrameda/SI3.jpg";
import tierpflege from "../homePage/assets/DeepInfosKrameda/tierpflege1.jpg";
import begleitung from "../homePage/assets/DeepInfosKrameda/beschaffung1.jpg";
import haushalt from "../homePage/assets/DeepInfosKrameda/Garten2.jpg";
import technik from "../homePage/assets/DeepInfosKrameda/technik2.jpg";


//Caregiver
// import KrissNoBg from "../../SVG/Selbstvorstellungen/KrissNoBg2.png"
import KrissNoBg from "../../SVG/Selbstvorstellungen/KrissNoBg1.png"
import MarioNoBg from "../../SVG/Selbstvorstellungen/MarioNoBg.png"


import ThemedText from "../../components/ThemedText"
import { Link } from "react-router-dom";


import DennisNoBg from "../../SVG/Selbstvorstellungen/DennisNoBg2.png"
import "./hilfsleistungenDetails.css";

//ANIMATION
import { useEffect } from 'react';

import { fadeInToLeft, fadeOutToRight, fadeInToRight, fadeOutToLeft } from '../../utilities/animation';
import Footer from '../../components/footer/Footer';

const blocks = [

    {
        id: "sozialeInteraktion",
        image: sozialeInteraktion,
        shortText: "Wir sind da",
        schlagwort: "Soziale Interaktion",
        link: "/hilfsleistungen/sozialeInteraktion",
        longText: "In Momenten der Einsamkeit oder einfach nur, wenn Sie jemanden zum Reden brauchen, stehen wir Ihnen zur Seite. Unsere sozialen Interaktionsdienste bieten nicht nur Gesellschaft, sondern auch Unterhaltung und ein offenes Ohr für Ihre Anliegen. Unter anderem Wir sind für Sie mit folgenden Punkten:",
        bulletPoints: [
            "Aktivitäten",
            "Kochen",
            "Spaziergänge",
            "Gedächtnisspiele und -aktivitäten",
            "Gemeinsames Lesen und Diskutieren",
            "Spiele und Unterhaltung",
        ],
    },
    {
        id: "tierpflege",
        image: tierpflege,
        shortText: "Wir kümmern uns",
        schlagwort: "Tierpflege",
        link: "/hilfsleistungen/tierpflege",
        longText: "Ihr Haustier ist mehr als nur ein Begleiter - es ist ein geschätztes Familienmitglied. Wir wissen, wie wichtig es ist, die Bedürfnisse Ihres pelzigen Freundes zu erfüllen. Daher kümmenr wir uns unter anderem um:",
        bulletPoints: [
            "Fütterung",
            "Gassi gehen",
            "Spiel und Spaß",
            "Tierarztbesuche",
            "Pflege und Hygiene",
            "und vieles mehr",
        ],
    },
    {
        id: "begleitung",
        image: begleitung,
        shortText: "Wir begleiten Sie",
        schlagwort: "Begleitung",
        link: "/hilfsleistungen/begleitung",
        longText: "Es gibt Momente, in denen die Gesellschaft eines Begleiters besonders wertvoll ist - sei es für einen Arztbesuch, einen entspannten Einkaufsbummel oder den Besuch einer aufregenden Theatervorstellung. Unsere Begleitdienste sind weit mehr als nur praktische Unterstützung; Wir begleiten sie unter anderem bei:",
        bulletPoints: [
            "Arztbesuchen",
            "Einkäufen",
            "Veranstaltungen",
            "Spaziergängen",
            "Ausflügen",
            "und vieles mehr",
        ],
    },
    {
        id: "haushalt",
        image: haushalt,
        shortText: "Wir machen den Haushalt",
        schlagwort: "Haushalt",
        link: "/hilfsleistungen/haushalt",
        longText: "Ein gepflegter und ordentlicher Haushalt bildet die Basis für ein angenehmes Wohnumfeld, das Ihnen Ruhe und Entspannung schenkt. Unsere Haushaltsdienste kümmern sich um:",
        bulletPoints: [
            "Putzen",
            "Aufräumen",
            "Wäsche waschen",
            "Bügeln",
            "und vieles mehr",
        ],
    },
    {
        id: "technik",
        image: technik,
        shortText: "Wir reparieren",
        schlagwort: "Technik",
        link: "/hilfsleistungen/technik",
        longText: "Für den Fall, dass Sie auf Probleme mit E-Mails oder dem Internet stoßen, stehen wir gerne zur Verfügung, um Ihnen zu helfen. Wir sind zwar keine Experten, aber wir werden unser Bestes tun, um Ihre technischen Herausforderungen zu lösen und Ihnen den bestmöglichen Service zu bieten. Wir richten:",
        bulletPoints: [
            "Computer",
            "Smartphones",
            "Tablets",
            "Internetverbindungen",
            "und vieles mehr ein",
        ],
    },
    {
        id: "beschaffung",
        image: beschaffung,
        shortText: "Wir bringen es",
        schlagwort: "Beschaffung",
        link: "/hilfsleistungen/beschaffung",
        longText: "Die Erledigung kleiner Besorgungen und das Abholen von Dingen können oft eine mühsame Herausforderung darstellen. Dank unserem zuverlässigen Botenservice können Sie sich entspannt zu Hause zurücklehnen, während wir uns um alles kümmern. Wir holen unteranderem für Sie ab:",
        bulletPoints: [
            "Medikamente von der Apotheke",
            "Lebensmittel vom Supermarkt",
            "Kleidung von der Reingung",
            "Rezepte von Arztpraxen",
            "Pakete abholen oder versenden",
            "und vieles mehr",
        ],
    }
];

const textIfSthMissing = "Sollte Ihnen etwas fehlen, sprechen Sie uns gerne an!";

const caregiverProfil = {
    dennis: DennisNoBg,
    mario: MarioNoBg,
    kristin: KrissNoBg
};

function getRandomCaregiverProfile() {
    const profiles = Object.values(caregiverProfil);
    const randomIndex = Math.floor(Math.random() * profiles.length);
    return profiles[randomIndex];
};


export default function HilfsleistungenDetails() {
    const currentURL = window.location.href;
    const parts = currentURL.split("/");
    const idHilfsleistung = parts[parts.length - 1]

    const imgCaregiverRef = useRef(null);
    const textRef = useRef(null);

    const [isVisible, setIsVisible] = React.useState(false);

    let selectedBlock = blocks.find(block => block.id === idHilfsleistung);

    if (!selectedBlock) {
        selectedBlock = blocks[0];
    }

    useEffect(() => {
        const randomProfile = getRandomCaregiverProfile();
        selectedBlock.caregiver = randomProfile;

        const observer = new IntersectionObserver(
            ([textEntry, imgEntry]) => {
                if (textEntry.isIntersecting) {
                    setIsVisible(1);
                }
                if (!imgEntry.isIntersecting) {
                    setIsVisible(0);
                }
            },
            {
                threshold: 0.5
            }
        );
        const tRef = textRef.current 
        const iCRef = imgCaregiverRef.current
        if (tRef && iCRef) {
            observer.observe(tRef);
            observer.observe(iCRef);
        }
    
        return () => {
            if (tRef && iCRef) {
                observer.unobserve(tRef);
                observer.unobserve(iCRef);
            }
        };
    }, [selectedBlock]);
    



    useEffect(() => {
        const fadeInToRight_ImgRef = fadeInToRight(textRef);
        const fadeOutToLeft_ImgRef = fadeOutToLeft(imgCaregiverRef);
        const fadeInToLeft_TextRef = fadeInToLeft(imgCaregiverRef);
        const fadeOutToRight_TextRef = fadeOutToRight(textRef);

            if (isVisible) {
                fadeInToRight_ImgRef.play();
                fadeInToLeft_TextRef.play();
            } else {
                fadeOutToLeft_ImgRef.play();
                fadeOutToRight_TextRef.play();
            }
        }, [isVisible]);
    
    return (
        <IonPage className="page-design">
            <SpaceForHeader />
            <IonContent >
                <IonGrid style={{ justifyContent: "center", padding: 0, margin: 0, }}>
                    <IonRow style={{ width: "100%", padding: 0, margin: 0, background: "#0a2351", display: "flex", justifyContent:"center", alignItems:"center"}}> {/* Brewers Blue */}
                        <IonCol sizeXs='12' sizeXl = "5" style = {{ margin:"0", padding: "0", marginTop: "50px", marginBottom:"50px", display: "flex", justifyContent:"center"}}>
                            <img className = "hilfsleistungenDetails-img" src={selectedBlock.image} alt="Personentransport" style={{ }} />
                        </IonCol>
                        <IonCol sizeXs='12' sizeXl = "6" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <p className="big-keyword">{selectedBlock.schlagwort}</p>
                        </IonCol>
                    </IonRow>
                    {/* TEXTBLOCK */}
                    <IonRow  style={{ minHeight: "1000px", width: "100%", margin: 0, display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center" }}>
                        <IonRow className="box">
                        <IonCol sizeXs='12' sizeXl='6' style={{ display: "flex", alignItems: "flex-end", justifyContent: "center", padding: 0, margin: 0 }}>
                                <img  ref={imgCaregiverRef} src={selectedBlock.caregiver} alt="Personentransport" style={{ width: "auto", height: "750px", objectFit: "contain", objectPosition: "center center", padding: 0, margin: 0}} />
                            </IonCol>
                            <IonCol ref={textRef} sizeXs='12' sizeXl='6' style={{ display: "flex", alignItems: "center", justifyContent: "center", textAlign: "justify", flexDirection: "column", paddingRight: "20px", paddingLeft: "20px"}}>
                                <ThemedText variant="h1-dark">{selectedBlock.shortText}</ThemedText>
                                <ThemedText variant="p-dark">{selectedBlock.longText}</ThemedText>
                                <br/>
                                {selectedBlock.bulletPoints && selectedBlock.bulletPoints.map((bulletPoint, index) => {
                                    return (
                                    <IonGrid style = {{width: "100%", maxHeight: "25px"}}>
                                    <li >
                                        <ThemedText variant="p-dark" key={index}>{bulletPoint}</ThemedText>
                                        </li>
                                    </IonGrid>
                                    )
                                })
                                }
                                <br/>
                                <br/>

                                <ThemedText variant="p-dark" >{textIfSthMissing}</ThemedText>
                                <br/>
                                <IonRow style = {{display:"flex", alignItems: "center", justifyContent: "center", textAlign: "justify"}}>
                                    <IonCol sizeXs="6" sizeXl="6" >
                                    <Link to= "/contact">
                                    <IonButton style={{minWidth: "200px",  height: "15%", minHeight: "45px", maxHeight: "65px", whiteSpace: "normal", wordWrap: "break-word" }} className="btn">
                                        Rückfrage
                                    </IonButton>
                                    </Link>
                                    </IonCol>
                                    <IonCol sizeXs="6" sizeXl="6" >
                                    
                                    <Link to= "/portfolio">
                                    <IonButton  style={{minWidth: "200px", height: "15%", minHeight: "45px", maxHeight: "65px", whiteSpace: "normal"}} className="btn">
                                        Unsere Preise
                                    </IonButton>
                                    </Link>
                                    </IonCol>



                                </IonRow>
                            </IonCol>
                        </IonRow>
                    </IonRow>
                </IonGrid>
                <Footer />
            </IonContent>
        </IonPage>
    )
}