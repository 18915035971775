import React from 'react';
import { createTheme, ThemeProvider, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export default function ThemedText(props) {
  const theme = useTheme();

  const CustomTheme = createTheme({
    palette: {
      dark: {
        main: '#00308F', // Air Force Blue
      },
      darkerBlue: {
        main: '#002244', // Broncos Navy
      },
      bright: {
        main: '#F0F8FF', // Alice Blue 
      },
      brighterBlue: {
        main: '#E0E8EE', // Alice Blue 
      },
      background: {
        default: '#ffffff', // Schneeweißer Hintergrund
      },
    },
    typography: {
      h1: {
        fontSize: 'clamp(30px, 4vw, 37.5px)',
      },
      h2: {
        fontSize: 'clamp(22px, 4vw, 29px)',
      },
      h3: {
        fontSize: 'clamp(18px, 4vw, 26px)',
      },
      p: {
        fontSize: 'clamp(16px, 3vw ,20px)',
      },
      pMini: {
        fontSize: '16px',
      },
      body1: {
        fontSize: '1rem',
      },
    },
    globalTypography: {
      fontSize: "60px",
      fontFamily: "Segoe UI, Tahoma, Geneva, Verdana, sans-serif",
      fontWeight: 100,
    },
    background: {
      default: 'line', // Schneeweißer Hintergrund
    }
  });

  const { variant, children, ...rest } = props;

  let style;
  switch (variant) {
    case 'slogan':
      style = {
        color: CustomTheme.palette.bright.main,
        fontSize: "clamp(20px, 4vw, 38px)",
        fontFamily: CustomTheme.globalTypography.fontFamily,
        fontWeight: 600,
        display: "flex",
        justifyContent: "center",
        textTransform: "uppercase",
        letterSpacing:"4px", 
        padding: "5px"
      };
      break;
    case 'h1-bright':
      style = {
        color: CustomTheme.palette.bright.main,
        fontSize: CustomTheme.typography.h1.fontSize,
        fontFamily: CustomTheme.globalTypography.fontFamily,
        fontWeight: 400,
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        textTransform: "uppercase",
        padding: "10px",

        whiteSpace: "pre-line"
      };
      break;
    case 'h1-dark':
      style = {
        color: CustomTheme.palette.dark.main,
        fontSize: CustomTheme.typography.h1.fontSize,
        fontFamily: CustomTheme.globalTypography.fontFamily,
        fontWeight: 400,
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        textTransform: "uppercase",
        padding: "10px",
        paddingTop: "25px",
        paddingBottom: "25px",
        whiteSpace: "pre-line"
      };
      break;
    case 'h2-bright':
      style = {
        color: CustomTheme.palette.bright.main,
        fontSize: CustomTheme.typography.h2.fontSize,
        fontFamily: CustomTheme.globalTypography.fontFamily,
        fontWeight: CustomTheme.globalTypography.fontWeight,
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        alignText: "center",
        padding: "8px",
        whiteSpace: "pre-line"
      };
      break;
    case 'h2-dark':
      style = {
        color: CustomTheme.palette.dark.main,
        fontSize: CustomTheme.typography.h2.fontSize,
        fontFamily: CustomTheme.globalTypography.fontFamily,
        fontWeight: 400,
        display: "flex",
        justifyContent: "center",
        alignText: "center",
        alignItems: "center",
        padding: "8px",
        whiteSpace: "pre-line"
      };
      break;
      case 'h3-bright':
        style = {
          color: CustomTheme.palette.bright.main,
          fontSize: CustomTheme.typography.h3.fontSize,
          fontFamily: CustomTheme.globalTypography.fontFamily,
          fontWeight: 400,
          display: "flex",
          justifyContent: "center",
          padding: "0px",
          whiteSpace: "pre-line"
        };
        break;
      case 'h3-dark':
        style = {
          color: CustomTheme.palette.dark.main,
          fontSize: CustomTheme.typography.h3.fontSize,
          fontFamily: CustomTheme.globalTypography.fontFamily,
          fontWeight: CustomTheme.globalTypography.fontWeight,
          whiteSpace: "pre-line",

        };
        break;

    case 'p-bright':
      style = {
        color: CustomTheme.palette.bright.main,
        fontSize: CustomTheme.typography.p.fontSize,
        fontFamily: CustomTheme.globalTypography.fontFamily,
        fontWeight: CustomTheme.globalTypography.fontWeight,
        whiteSpace: "pre-line",
      };
      break;
      case 'p-bright-mini':
        style = {
          color: CustomTheme.palette.brighterBlue.main,
          fontSize: CustomTheme.typography.pMini.fontSize,
          fontFamily: CustomTheme.globalTypography.fontFamily,
          fontWeight: CustomTheme.globalTypography.fontWeight,
          whiteSpace: "pre-line",
        };
      break;
    case 'p-dark':
      style = {
        color: CustomTheme.palette.dark.main,
        fontSize: CustomTheme.typography.p.fontSize,
        fontFamily: CustomTheme.globalTypography.fontFamily,
        fontWeight: CustomTheme.globalTypography.fontWeight,
        whiteSpace: "pre-line",
      };
      break;
      case 'p-dark-mini':
        style = {
          color: CustomTheme.palette.darkerBlue.main,
          fontSize: CustomTheme.typography.pMini.fontSize,
          fontFamily: CustomTheme.globalTypography.fontFamily,
          fontWeight: CustomTheme.globalTypography.fontWeight,
          whiteSpace: "pre-line",
        };
      break;
      case 'li-bright':
        style = {
          color: CustomTheme.palette.bright.main,
          fontSize: CustomTheme.typography.li.fontSize,
          fontFamily: CustomTheme.globalTypography.fontFamily,
          fontWeight: CustomTheme.globalTypography.fontWeight,
          display: "flex",
          justifyContent: "center",
          padding: "8px",
          whiteSpace: "pre-line",
        };
        break;
      case 'li-dark':
        style = {
          color: CustomTheme.palette.dark.main,
          fontSize: CustomTheme.typography.li.fontSize,
          fontFamily: CustomTheme.globalTypography.fontFamily,
          fontWeight: CustomTheme.globalTypography.fontWeight,
          display: "flex",
          justifyContent: "center",
          padding: "8px",
          whiteSpace: "pre-line",

        };
        break;
      
      case 'map-h1-bright':
        style = {
          color: CustomTheme.palette.bright.main,
        fontSize: "55px",
        fontFamily: CustomTheme.globalTypography.fontFamily,
        fontWeight: CustomTheme.globalTypography.fontWeight,
        display: "flex",
        justifyContent: "center",
        textTransform: "uppercase",
        letterSpacing: "12px",
        padding: "25px",
        whiteSpace: "pre-line",
      };
      break;
    case 'header-link':
      style = {
        color: CustomTheme.palette.dark.main,
        fontSize: "clamp(12px, 2vw, 18px)",
        fontFamily: CustomTheme.globalTypography.fontFamily,
        fontWeight: 500,
        textTransform: "uppercase",
      };
      break;
    default:
      style = { color: theme.palette.text.secondary }; // Default style
      break;
  }

  return (
    <ThemeProvider theme={CustomTheme}>
      <Typography variant={variant} style={style} {...rest}>
        {children}
      </Typography>
    </ThemeProvider>
  );
}



// ### Colors
// 1. Farbverläufe für section 2, 4, 5 -> linear-gradient(165deg, #3b537a 0%, #5072A7 40%, transparent 10%)
// 2. Farbe für Section 5 - Vorstellung - Cards der Personen: #E1EBEE (Marian blue)  

