import React, { useState } from 'react';

import CSDImg from '../../../SVG/Events/2024_06_CSD/CSD_1.jpeg';
import SCESommerfest_5 from '../../../SVG/Events/2024_06_SCE-Sommerfest/SCE_Sommerfest_5.jpeg';
import First_Task_3 from '../../../SVG/Events/Aufträge/First_Task_3.jpeg';
import First_Task_5 from '../../../SVG/Events/Aufträge/First_Task_5.jpeg';

import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

import { IonGrid } from '@ionic/react';

import './ImagesContainer.css';
import ThemedText from '../../../components/ThemedText';

export default function ImagesContainer() {
    const itemData = [
        { img: CSDImg, title: "CSD - 06/2024" },
        { img: SCESommerfest_5, title: "SCE Sommerfest - 06/2024" },
        { img: First_Task_3, title: "Erster Auftrag - 06/2024" },
        { img: First_Task_5, title: "Erster Auftrag - 06/2024" },
    ];

    const [open, setOpen] = useState(false);
    const [selectedImg, setSelectedImg] = useState(null);

    const handleOpen = (imgSrc) => {
        setSelectedImg(imgSrc);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <IonGrid style = {{marginBottom: "100px"}}>
            <ThemedText variant = "h1-dark">Besondere Momente</ThemedText>
            <ImageList
                variant="masonry"
                className = "image-list-container"
                
                cols={2} // Anzahl der Spalten erhöht, um Bilder kleiner zu machen
                gap={8}
            >
                {itemData.map((item) => (
                    <ImageListItem key={item.img} className="image-list-item">
                        <img
                            className = "image-list-item"
                            src={item.img}
                            alt={item.title}
                            loading="lazy"
                            onClick={() => handleOpen(item.img)}
                        />
                        <ImageListItemBar
                            title={item.title}
                            actionIcon={
                                <IconButton
                                    sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                    aria-label={`info about ${item.title}`}
                                >
                                </IconButton>
                            }
                        />
                    </ImageListItem>
                ))}
            </ImageList>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    maxheight: "600px",
                }}>
                    <img src={selectedImg} alt="enlarged" className = "modal-img" />
                </Box>
            </Modal>
        </IonGrid>
    );
}
