import { IonContent } from "@ionic/react";
import SpaceForHeader from "../../components/header/SpaceForHeader";
import ThemedText from "../../components/ThemedText";
import { IonPage, IonGrid, IonRow, IonCol } from "@ionic/react";

import './contact.css';
import Footer from "../../components/footer/Footer";

import { useEffect, useState } from "react";

export default function Contact() {

    const [smallScreen, setSmallScreen] = useState(false);

    useEffect(() => {
        const mediaQuery = window.matchMedia('(max-width: 700px)');
        const handleMediaQueryChange = (event) => {
            setSmallScreen(event.matches);
        };
        mediaQuery.addEventListener('change', handleMediaQueryChange);
        setSmallScreen(mediaQuery.matches);
        return () => {
            mediaQuery.removeEventListener('change', handleMediaQueryChange);
        };
    }, []);
    


    return (
        <IonPage className="page-design">
            <IonContent >
                <SpaceForHeader />
                <IonGrid className="doubleblueline-whiteMiddle" style={{ padding: 0, minHeight: "80vh" }}>
                    <IonGrid style={{ padding: 0}}>
                        <IonGrid className = "title-box"> 
                            <IonRow size = "11" className="row-contactUs">
                                <IonGrid >
                                <ThemedText variant="h1-bright">Kontakt</ThemedText>
                                </IonGrid>
                                <ThemedText variant="p-bright">
                                    Haben Sie Fragen zu unserem Service, zu den Menschen hinter unserem Unternehmen, unserer Vision oder möchten Sie einfach Ihre Gedanken und Ideen mit uns teilen? Wir sind hier, um Ihnen zuzuhören und Ihnen zu helfen.
                                </ThemedText>
                                <br />
                                <ThemedText variant="p-bright">
                                    Unabhängig davon, wie groß oder klein Ihre Anliegen sind, zögern Sie nicht, uns zu kontaktieren. Wir sind fest entschlossen, Ihnen mit Rat und Tat zur Seite zu stehen.
                                </ThemedText>
                                <br />
                                <ThemedText variant="p-bright">
                                    Wenn Sie daran interessiert sind, mit uns zusammenzuarbeiten oder eine Partnerschaft einzugehen, stehen wir Ihnen mit offenen Armen gegenüber. Gemeinsam können wir Großes erreichen.
                                </ThemedText>
                                <br />
                                <ThemedText variant="p-bright">
                                    Ihre Nachricht bedeutet uns viel und wir freuen uns darauf, von Ihnen zu hören. Lassen Sie uns gemeinsam eine positive Veränderung bewirken.
                                </ThemedText>
                            </IonRow>
                        </IonGrid>
                        <IonRow style={{ alignItems: "center" }}>
                            <IonCol sizeLg="6" sizeXs="12" style={{ minHeight: "175px", textAlign: "center", display: "flex", flexDirection: "column" }} className="blueCol">
                                <ThemedText variant="h2-bright">Geschäftsführung</ThemedText>
                                <ThemedText variant="p-bright">Kristin Schermer</ThemedText>
                                <ThemedText variant="p-bright">Mario Faschinger</ThemedText>
                                <ThemedText variant="p-bright">Dennis Stanielewitz</ThemedText>
                            </IonCol>
                            <IonCol sizeLg="6" sizeXs="12" style={{ backgroundColor: "white", minHeight: "175px", textAlign: "center", display: "flex", flexDirection: "column" }}>
                                <ThemedText variant="h2-dark">Adresse</ThemedText>
                                <ThemedText variant="p-dark">Krameda Alltagshilfe OHG</ThemedText>
                                <ThemedText variant="p-dark">Winterstr. 8</ThemedText>
                                <ThemedText variant="p-dark">81543 München</ThemedText>
                            </IonCol>
                        </IonRow>
                        <IonRow style={{ alignItems: "center" }}>

                            {!smallScreen ?
                                <>
                                    <IonCol sizeLg="6" sizeXs="12" style={{ minHeight: "175px", textAlign: "center", backgroundColor: "white" }}>
                                        <ThemedText variant="h2-dark">Email</ThemedText>
                                        <a href="mailto:team@krameda.de" className="mailTo">
                                            <ThemedText variant="p-dark">
                                                team@krameda.de
                                            </ThemedText>
                                        </a>
                                    </IonCol>
                                    <IonCol sizeLg="6" sizeXs="12" style={{ minHeight: "175px", textAlign: "center" }} className="blueCol">
                                        <ThemedText variant="h2-bright">Telefon</ThemedText>
                                        <ThemedText variant="p-bright">XXXX XXXXX</ThemedText>
                                    </IonCol>
                                </>
                            :
                            <>
                            <IonCol sizeLg="6" sizeXs="12" style={{ minHeight: "175px", textAlign: "center" }} className="blueCol">
                                <ThemedText variant="h2-bright">Telefon</ThemedText>
                                <ThemedText variant="p-bright">XXXX XXXXX</ThemedText>
                            </IonCol>
                            <IonCol sizeLg="6" sizeXs="12" style={{ minHeight: "175px", textAlign: "center", backgroundColor: "white" }}>
                                        <ThemedText variant="h2-dark">Email</ThemedText>
                                        <a href="mailto:team@krameda.de" className="mailTo">
                                            <ThemedText variant="p-dark">
                                                team@krameda.de
                                            </ThemedText>
                                        </a>
                                    </IonCol>
                            </>
                            }
                        </IonRow>
                    </IonGrid>
                </IonGrid>
                <Footer />
            </IonContent>
        </IonPage>
    );
}