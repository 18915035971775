import { IonContent } from "@ionic/react";
import SpaceForHeader from "../../components/header/SpaceForHeader";
import ThemedText from "../../components/ThemedText";
import { IonPage, IonGrid} from "@ionic/react";

import Footer from "../../components/footer/Footer";


export default function Datenschutz() {
    return (
        <IonPage className="page-design">
        <IonContent>
          <SpaceForHeader />
          <IonGrid style = {{padding: "40px"}}>
          <ThemedText variant="h1-dark">Datenschutzerklärung</ThemedText>
          <p>
            Die Krameda Alltagshilfe GbR, Winterstr. 8, 81543 München (im Folgenden: „wir“) ist als Betreiberin der Plattform [URL ergänzen] (im Folgenden „Plattform“) Verantwortlicher für die Verarbeitung personenbezogener Daten der Nutzer (im Folgenden: „Sie“) der Plattform im Sinne der Datenschutzgesetze, insbesondere der Datenschutz-Grundverordnung („DSGVO") und des Bundesdatenschutzgesetzes („BDSG“).
          </p>
          <p>
            Mit dieser Datenschutzerklärung informieren wir Sie, welche personenbezogenen Daten wir bei der Nutzung unserer Plattform, insbesondere bei Nutzung unseres Kontaktformulars, verarbeiten und bitten Sie daher die nachfolgenden Ausführungen sorgfältig durchzulesen.
          </p>
          <ThemedText variant="h2-dark">1. Bereitstellung der Plattform, Logfiles</ThemedText>
          <p>
            Bei der Bereitstellung der Plattform und der Nutzung unserer Dienste verarbeiten wir personenbezogene Daten. Hierzu zählen auch Informationen über die Nutzung der Plattform. In diesem Zusammenhang erheben wir, bzw. unser Hosting-Provider folgende personenbezogenen Daten:
            <ul>
              <li>Ihre IP-Adresse,</li>
              <li>Datum und Uhrzeit der Anfrage,</li>
              <li>Inhalt der Anforderung,</li>
              <li>Zugriffsstatus/HTTP-Statuscode,</li>
              <li>Umfang des Datentransfers,</li>
              <li>Webseite, von der Ihre Anfrage weitergeleitet wird,</li>
              <li>Browser, Betriebssystem und dessen Oberfläche, Sprache und Version der Browsersoftware,</li>
              <li>sowie andere Verbindungsdaten und Quellen, die Sie abrufen.</li>
            </ul>
            Dies geschieht in der Regel durch die Verwendung von Logfiles und Cookies. Nähere Informationen zu Cookies erhalten Sie unter Ziffer 2.
          </p>
          <ThemedText variant="h2-dark">2. Cookies</ThemedText>
          <p>
            Wir erheben außerdem Informationen über Ihre Nutzung unserer Plattform durch die Verwendung von Cookies. Ein Cookie enthält in der Regel den Namen der Webseite von der die Cookie-Daten gesendet werden sowie Informationen über das Alter des Cookies und ein alphanumerisches Identifizierungszeichen (Cookie-ID). Cookies ermöglichen unseren Systemen, Ihr Endgerät zu erkennen und eventuelle Voreinstellungen sofort verfügbar zu machen. Sobald Sie auf die Plattform zugreifen, wird ein Cookie auf Ihrem Endgerät gespeichert.
          </p>
          <p>
            Auf der Plattform nutzen wir derzeit nur Cookies, die unbedingt notwendig sind, damit wir Ihnen die Plattform bereitstellen können (im Folgenden „Notwendige Cookies“). Notwendige Cookies werden gesetzt, wenn Sie die Plattform aufrufen.
          </p>
          <ThemedText variant="h2-dark">3. Webhosting-Dienst IONOS</ThemedText>
          <p>
            Für das Hosting der Plattform nutzen wir den Hosting-Anbieter [IONOS SE, Elgendorfer Str. 57, 56410 Montabaur (im Folgenden „IONOS“)]. Wir übermitteln [IONOS] die erforderlichen Daten, die [IONOS] für das ordnungsgemäße Hosting der Plattform benötigt.
          </p>
          <ThemedText variant="h2-dark">4. Kontaktformular</ThemedText>
          <p>
            Auf der Plattform stellen wir Ihnen ein Kontaktformular, mit der Möglichkeit, mit uns schriftlich oder über unseren Rückrufservice (mit Anrufbeantworter) in Kontakt zu treten, bereit. Bei der Inanspruchnahme der Kontaktmöglichkeiten werden bestimmte personenbezogene Daten von Ihnen erhoben, um Ihre Anfrage zu bearbeiten.
          </p>
          <ThemedText variant="h2-dark">5. Leistungserbringung und Vertragsdurchführung</ThemedText>
          <p>
            Soweit dies für die Erbringung unserer Leistungen erforderlich ist, geben wir Ihre Daten an die Person weiter, die Ihren Auftrag über die Plattform annimmt und ausführen wird.
          </p>
          <ThemedText variant="h2-dark">6. Keine Weitergabe Ihrer personenbezogenen Daten</ThemedText>
          <p>
            Wir geben Ihre personenbezogenen Daten nicht an Dritte weiter, es sei denn, in dieser Datenschutzerklärung ist etwas anderes geregelt, Sie haben in die Datenweitergabe eingewilligt oder wir sind aufgrund gesetzlicher Bestimmungen und/oder behördlicher oder gerichtlicher Anordnungen zu einer Datenweitergabe berechtigt und/oder verpflichtet.
          </p>
          <ThemedText variant="h2-dark">7. Datensicherheit</ThemedText>
          <p>
            Alle Informationen, die Sie an uns übermitteln, werden auf Servern innerhalb der Europäischen Union gespeichert. Wir sichern unsere Plattform und sonstigen Systeme durch technische und organisatorische Maßnahmen gegen Verlust, Zerstörung, Zugriff, Veränderung oder Verbreitung Ihrer Daten durch unbefugte Personen ab.
          </p>
          <ThemedText variant="h2-dark">8. Löschung</ThemedText>
          <p>
            Wir speichern Ihre Daten so lange, wie dies erforderlich ist. Sofern Sie von Widerspruchsrecht Gebraucht gemacht haben, oder die Rechtsgrundlage aus einem anderen Grund, insbesondere weil der mit ihrer Erhebung verbundene Zweck erreicht wurde, nachträglich entfällt, werden wir die Daten unverzüglich löschen, sofern wir nicht aufgrund einer gesetzlichen Aufbewahrungspflicht dazu verpflichtet sind, die Daten zu behalten.
          </p>
          <ThemedText variant="h2-dark">9. Datenschutz und Webseiten Dritter</ThemedText>
          <p>
            Die Plattform kann Hyperlinks zu und von Webseiten Dritter enthalten. Wenn Sie einem Hyperlink zu einer dieser Webseiten folgen, sind die Betreiber der verlinkten Webseite Verantwortliche für die Verarbeitung Ihrer personenbezogenen Daten auf diesen Webseiten.
          </p>
          <ThemedText variant="h2-dark">10. Änderungen dieser Datenschutzbestimmungen</ThemedText>
          <p>
            Wir behalten uns vor, diese Datenschutzbestimmungen jederzeit mit Wirkung für die Zukunft zu ändern. Eine jeweils aktuelle Version ist auf der Plattform verfügbar. Bitte suchen Sie die Plattform regelmäßig auf und informieren Sie sich über die geltenden Datenschutzbestimmungen.
          </p>
          <ThemedText variant="h2-dark">11. Ihre Rechte und Kontakt</ThemedText>
          <p>
            Sie können Ihre personenbezogenen Daten jederzeit kostenlos einsehen sowie Auskunft zu deren Herkunft, Empfängern oder Kategorien von Empfängern, an die diese Daten weitergegeben werden und den Zweck der Speicherung und ggf. deren Berichtigung und/oder Löschung und/oder Einschränkung ihrer Verarbeitung verlangen. Zudem steht Ihnen das Recht auf Datenübertragbarkeit zu.
          </p>
          <p>
            Wenn Sie der Ansicht sind, dass die Verarbeitung Ihrer Daten gegen Datenschutzgesetze verstößt, sind Sie berechtigt, sich hierüber bei einer Datenschutzbehörde zu beschweren.
          </p>
          <p>
            Sollten Sie Fragen, Kommentare oder Anfragen bezüglich der Erhebung, Verarbeitung und Nutzung Ihrer personenbezogenen Daten durch uns haben oder um Ihre oben genannten Rechte auszuüben, wenden Sie sich an uns per E-Mail an team@krameda.de oder per Brief an die oben angegebene Adresse.
          </p>
          <p>
            Stand: Juni 2024
          </p>
  
          </IonGrid>
          <Footer />

          </IonContent>
      </IonPage>
    );
  }
  