import React from 'react';
import { IonGrid} from '@ionic/react';
import './questionmarkModal.css';
import ThemedText from './ThemedText';

import { Box } from '@mui/material';
import Modal from '@mui/material/Modal';



export default function QuestionmarkModal({title, text}) {


    const [open, setOpen] = React.useState(false);

    return (
        <IonGrid className="grid-questionmark">
            <p className="questionMark"
                onClick={() => setOpen(true)}
            >
                ?
            </p>
            <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={() => setOpen(false)}
        
      >
                <Box className = "modal-questionmark" >
                    <IonGrid className='grid-modal-title'>
                    <h2 className="modal-title">
                        {title}
                    </h2>
                    </IonGrid>
                    <IonGrid className = "grid-modal-text">

                    <ThemedText variant="p-bright">
                     {text}
                    </ThemedText>
                    </IonGrid>
                </Box>
            </Modal>

        </IonGrid>
    );
}