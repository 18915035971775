import { IonGrid, IonCol, IonRow} from '@ionic/react';


import "../homePage.css"

import ThemedText from "../../../components/ThemedText";
//Images 
// import personentransport from "../assets/DeepInfosKrameda/personenTransport.jpg";
// import sozialeInteraktion from "../assets/DeepInfosKrameda/sozialeInteraktion.jpg";
// import tierpflege from "../assets/DeepInfosKrameda/tierpflege.jpg";
// import begleitung from "../assets/DeepInfosKrameda/begleitung.jpg";
// import haushalt from "../assets/DeepInfosKrameda/haushalt.jpg";
// import technik from "../assets/DeepInfosKrameda/technik.jpg";
import beschaffung from "../assets/DeepInfosKrameda/begleitung.jpg";
import sozialeInteraktion from "../assets/DeepInfosKrameda/SI3.jpg";
import tierpflege from "../assets/DeepInfosKrameda/tierpflege1.jpg";
import begleitung from "../assets/DeepInfosKrameda/beschaffung1.jpg";
import haushalt from "../assets/DeepInfosKrameda/Garten2.jpg";
import technik from "../assets/DeepInfosKrameda/technik2.jpg";

import { Link } from 'react-router-dom'; 

export default function DeepInfosKrameda() {

    const blocks = [
        {
            id: "beschaffung",
            image: beschaffung,
            text: "Wir bringen es",
            schlagwort: "Beschaffung",
            link: "/hilfsleistungen/beschaffung"
        },
        {
            id: "sozialeInteraktion",
            image: sozialeInteraktion,
            text: "Wir sind da",
            schlagwort: "Soziale Interaktion",
            link: "/hilfsleistungen/sozialeInteraktion"
        },
        {
            id: "tierpflege",
            image: tierpflege,
            text: "Wir kümmern uns",
            schlagwort: "Tierpflege",
            link: "/hilfsleistungen/tierpflege"
        },
        {
            id: "begleitung",
            image: begleitung,
            text: "Wir begleiten Sie",
            schlagwort: "Begleitung",
            link: "/hilfsleistungen/begleitung"
        },
        {
            id: "haushalt",
            image: haushalt,
            text: "Wir machen den Haushalt",
            schlagwort: "Haushalt",
            link: "/hilfsleistungen/haushalt"
        },
        {
            id: "technik",
            image: technik,
            text: "Wir reparieren",
            schlagwort: "Technik",
            link: "/hilfsleistungen/technik"
        }
    ];

    return (
        <IonGrid className = "background-DeepInfosKrameda" >
            <IonGrid style = {{paddingTop:"30px", margin:0, maxHeight: "5%"}}>
                <ThemedText variant="h1-dark">Wir helfen bei...</ThemedText>
            </IonGrid>
            <IonGrid style={{ height: "100%", width: "100%" }}>
                {
                    blocks.map((element, index) => ( 
                        index % 3 === 0 && ( //Index % 3 um jede 3. Zeile eine neue IonRow zu erstellen
                            <IonRow key={index}>
                                {blocks.slice(index, index + 3).map((item, subIndex) => ( //Wähl di enächstne drei elemente aus - iteriere durch jedes dieser drei und erstelle IonCol für jedes
                                    <IonCol key={subIndex} size="4" size-md="4" size-lg="4">
                                        <Link to={item.link} style = {{textDecoration: "none"}}>
                                            <IonGrid className="interactive-image" style={{ backgroundImage: `url(${item.image})` }}>
                                                <IonGrid className="interactive-image-overlay">
                                                    <p className="interactive-image-text">{item.text}</p>
                                                </IonGrid>
                                            </IonGrid>
                                            <p className="interactive-image-schlagwort">{item.schlagwort}</p>
                                        </Link>
                                    </IonCol>
                                ))}
                            </IonRow>
                        )
                    ))}
            </IonGrid>
        </IonGrid>
    );
}
