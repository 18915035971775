import React, { useEffect, useRef } from 'react';
import { } from '@ionic/react'; // Importieren Sie IonContent und IonGrid aus @ionic/react
import ThemedText from '../../../components/ThemedText';
import LogoKrameda from "../../../SVG/Logo/Krameda_WithoutBackground.png";
import { IonButton, IonRow, IonGrid, IonCol  } from '@ionic/react';

import { createAnimation } from 'https://cdn.jsdelivr.net/npm/@ionic/core@latest/dist/esm/index.mjs';
import { useSelector } from 'react-redux';

import { Link } from "react-router-dom";

import "./promotionKrameda.css";

export default function PromotionKrameda({scrollToSection}) {
  const imgRef = useRef(null);
  const logo = useSelector(state => state.logo.logoState);

  function animateImageIn() {
    const animation = createAnimation()
      .addElement(imgRef.current)
      .duration(1250)
      .iterations(1)
      .fromTo('filter', 'blur(10px)', 'blur(0px)')
      .fromTo('transform', 'scale(0.5)', 'scale(1)')
    animation.play();
  }

  function animateImageOut() {
    const animation = createAnimation()
      .addElement(imgRef.current)
      .duration(1250)
      .iterations(1)
      .fromTo('filter', 'blur(10px)', 'blur(0px)')
      .fromTo('transform', 'scale(1)', 'scale(0)')
    animation.play();
  }

  useEffect(() => {
    if(logo.logoState === true){
      animateImageOut();
    }else{
      animateImageIn();
    }
  }, [logo.logoState])

  return (
    <IonGrid style={{ padding: 0, margin: 0 }}>
      <IonGrid className="promotion-krameda" >
        <IonGrid style = {{height: "clamp(fit-content, 100%, 600px", background:"#00000050"}}>
          <IonRow style={{ display: "flex", justifyContent: "center", textAlign: "center", alignItems: "center" }}>
            <img ref = {imgRef} src={LogoKrameda} alt="Krameda Logo" style={{maxHeight: "300px",  objectFit: "contain", width: "40%" }} />
          </IonRow>
          <IonRow style={{ display: "flex", justifyContent: "center", textAlign: "center", alignItems: "center" }}>
            <IonGrid style = {{height: "100%"}}>
              <ThemedText variant="slogan">Ihre Alltagshilfe von Nebenan!</ThemedText>
              <ThemedText variant="p-bright">Flexible, spontane und lokale Hilfe in München - Vereinfachen Sie ihr Leben mit Krameda!</ThemedText>
              <IonRow  className ="row-btn-krameda-promo">
                <IonCol size = "5.5" style = {{display: "flex", justifyContent: "flex-end"}}>
                  <IonButton variant="dark" className = "btn-krameda-promo" onClick={scrollToSection} >
                    Was wir anbieten
                  </IonButton>
                </IonCol>
                <IonCol size = "5.5" style = {{display: "flex", justifyContent: "start"}}>
                  <Link to="/portfolio" style={{ textDecoration: "none", width: "100%",display: "flex", justifyContent: "start" }}>
                    <IonButton className = "btn-krameda-promo" >
                      Jetzt Hilfe finden
                    </IonButton>
                  </Link>
                  </IonCol>
              </IonRow>
            </IonGrid>
          </IonRow>
        </IonGrid>
      </IonGrid>
    </IonGrid>
  );
}
