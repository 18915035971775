import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './store';
import { Provider } from 'react-redux';
import { IonApp } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

ReactDOM.render(
  <IonApp>
    <IonReactRouter>
        <Provider store={store}>
          <App/>
        </Provider>
    </IonReactRouter>
  </IonApp>,
  document.getElementById('root')
);

reportWebVitals();

